import { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import AuthContext from '../../store/auth-context';
import { Api, userRoles } from '../../utils/api';
import classes from './MainNavigation.module.css';

import logo from './logo.png';

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutHandler = () => {
    Api.logout().then((data) => {navigate('/logout');});
  }

  return (
    <header className={classes.header}>
      {/* <div className={classes.logo}>Диагностика</div> */}
      <div className={classes.logo}><img src={logo} /></div>
      <nav>
      {authCtx.isLoggedIn && (
        <ul>
            {/* <li>
              <NavLink to='/profile' className={({isActive}) => isActive ? classes.active : undefined}>Профил</NavLink>
            </li> */}
            {authCtx.role === userRoles.superAdmin && 
            <li>
              <NavLink to='/companies' className={({isActive}) => isActive ? classes.active : undefined}>Фирми</NavLink>
            </li>}
            {authCtx.role !== userRoles.employee && (
            <li>
              <NavLink to='/users' className={({isActive}) => isActive ? classes.active : undefined}>Потребители</NavLink>
            </li>
            )}
            <li>
              <NavLink to='/patients' className={({isActive}) => isActive ? classes.active : undefined}>Пациенти</NavLink>
            </li>
            <li>
              <NavLink to='/diagnostics' className={({isActive}) => isActive ? classes.active : undefined}>Диагностики</NavLink>
            </li>
            <li>
              <button onClick={logoutHandler}>Изход</button>
            </li>
        </ul>
      )}
      </nav>
    </header>
  );
};

export default MainNavigation;