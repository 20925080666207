import { useState, useMemo } from "react";
import { Link, useLoaderData, useNavigate, useOutletContext, useRouteLoaderData } from "react-router-dom";
import { useDebounce } from "use-debounce";
import ListWrapper from "../../components/UI/ListWrapper";
import Modal from "../../components/UI/Modal";
import SearchBox from "../../components/UI/SearchBox";
import { Api, getUserRoleName, userRoles } from '../../utils/api';
import Pagination from "../../components/UI/Pagination";

import classes from '../../components/UI/ListWrapper.module.css';

let pageSize = process.env.REACT_APP_PAGESIZE;

const UsersList = () => {
    const checkedUser = useRouteLoaderData('users');
    const [usersData, setUsersData] = useState(useLoaderData());
    const [modalIsShown, setModalIsShown] = useState(false);
    const [elemKey, setElemKey] = useState('');
    const [searchName, setSearchName] = useState('');
    const [debouncedText] = useDebounce(searchName, 800);
    const [filterRole, setFilterRole] = useState('');

    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();

    const [serverErrors, setServerErrors] = useOutletContext(usersData.errors);

    const usersDataFunc = async () => {
        const filter = {};
        filter.name = debouncedText;
        filter.role = filterRole;

        Api.getUsers(filter, currentPage, pageSize).then(
            (usersData) => {
                setUsersData({users: usersData, errors: []});
                setServerErrors([]);
            },
            (errors) => {
                setServerErrors(errors.errors);
              }
        );
    }

    useMemo(async () => {
        usersDataFunc();
      }, [currentPage, debouncedText, filterRole]);

    const deleteUserHandler = (id) => {
        Api.deleteUser(id).then(async (result) => {
            if (result === true) {
                usersDataFunc();
                setServerErrors([]);
            }
        }, (errors) => {
            setServerErrors(errors.errors);
          });
    }

    const searchBoxHandler = async (event) => {
        setSearchName(event.target.value);
        setCurrentPage(1);
        usersDataFunc();
    };

    const userFilterHandler = async (event) => {
        setFilterRole(event.target.value);
        setCurrentPage(1);
        usersDataFunc();
    };

    const clearFilterHandler = async () => {
        setSearchName('');
        setFilterRole('');
        setCurrentPage(1);
        usersDataFunc();
    };

    return (
        <>
        {modalIsShown && 
        <Modal message={'Сигурни ли сте?'} onCancel={() => {setModalIsShown(false);}} onConfirm={() => {deleteUserHandler(elemKey); setModalIsShown(false);}}></Modal>}
        <section className='lists'>
            <h1>Потребители</h1>
            <SearchBox>
             <div className='filter-wrapper'>
                <div className='control'>
                    <label htmlFor=''>Филтрирай по име:</label>
                    <input type='search' name={searchName} value={searchName} onChange={searchBoxHandler} />
                </div>
                {checkedUser === userRoles.superAdmin &&
                <div className='control'>
                    <label htmlFor=''>Филтрирай по роля:</label>
                    <select name='selectedRole' value={filterRole} onChange={userFilterHandler}>
                        <option value=''>- всички -</option>
                        <option value={userRoles.superAdmin}>Супер администратор</option>
                        <option value={userRoles.admin}>Администратор</option>
                        <option value={userRoles.employee}>Служител</option>
                    </select>
                </div>
                }
                <div className="action"><button className="cancel" onClick={clearFilterHandler}>Изчисти</button></div>
             </div>
            </SearchBox>
            <div className="action"><button onClick={() => {
                                    navigate('/users/new');
                                }}>Добави нов</button></div>
            <ListWrapper>
                {usersData.users.total === 0 ? 
                    <p>Няма потребители.</p> :
                    <table>
                        <thead>
                            <tr>
                                <th>Име</th>
                                <th>Роля</th>
                                <th>E-mail</th>
                                {checkedUser === userRoles.superAdmin && <th>Фирма</th>}
                                <th className={classes.actions}>Редактирай | Изтрий</th>
                            </tr>
                        </thead>
                        <tbody>
                        {usersData.users.users.map((user) => (
                            <tr key={user.id}>
                                <td><Link to={String(user.id)}>{user.name} {user.family}</Link></td>
                                <td>
                                    {getUserRoleName(user.role)}
                                </td>
                                <td>{user.email}</td>
                                {/* {checkedUser === userRoles.superAdmin && <td>{user.company.name}</td>} */}
                                {checkedUser === userRoles.superAdmin && <td>{user.company}</td>}
                                <td className={classes.actions}>
                                    <button className={classes.edit} onClick={() => {
                                        navigate(user.id + '/edit');
                                    }}></button>
                                    <button className={classes.delete} onClick={() => {
                                        setModalIsShown(true);
                                        setElemKey(user.id);
                                    }}></button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
            </ListWrapper>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={usersData.users.total}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </section>
        </>
    );
  }
  
  export default UsersList;