const ElasticityModel = {
    elasticity_norm: '1',
    elasticity_weakMuscle: '2',
    // elasticity_imbalance: '3',
    // elasticity_shortLeft: '4',
    // elasticity_shortRight: '5',
    // elasticity_shortMoreLeft: '6',
    // elasticity_shortMoreRight: '7',
    // elasticity_short: '8',
    getElasticityString: (elasticity) => {
        switch (elasticity) {
            case ElasticityModel.elasticity_norm: 
                return 'в норма';
            case ElasticityModel.elasticity_weakMuscle: 
                return 'слаба мускулатура';
            // case ElasticityModel.elasticity_imbalance: 
            //     return 'мускулен дисбаланс';
            // case ElasticityModel.elasticity_shortLeft: 
            //     return 'скъсяване отляво';
            // case ElasticityModel.elasticity_shortRight: 
            //     return 'скъсяване отдясно';
            // case ElasticityModel.elasticity_shortMoreLeft: 
            //     return 'скъсяване повече отляво';
            // case ElasticityModel.elasticity_shortMoreRight: 
            //     return 'скъсяване повече отдясно';
            // case ElasticityModel.elasticity_short: 
            //     return 'скъсяване';
        }
    }
};

export default ElasticityModel;