const LumbarModel = {
    lumbar_norm: '1',
    lumbar_possibleLord: '2',
    lumbar_lord: '3',
    getLumbarString: (lumbar) => {
        switch (lumbar) {
            case LumbarModel.lumbar_norm: 
                return 'в норма';
            case LumbarModel.lumbar_possibleLord: 
                return 'съмнение за изгладена поясна лордоза, препоръки: рентгенова снимка';
            case LumbarModel.lumbar_lord: 
                return 'изгладена поясна лордоза';
        }
    }
};

export default LumbarModel;