import { useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Api } from '../../utils/api';
import AuthContext from '../../store/auth-context';

import classes from './AuthForm.module.css';
import LoadingSpinner from '../../components/UI/LoadingSpinner';

const AuthForm = () => {

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const { register, formState: { errors }, handleSubmit } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [serverErrors, setServerErrors] = useOutletContext([]);

  const onSubmit = (loginData) => {
    setIsSubmitting(true);

    Api.login(loginData).then((data) => {
      if (data.token) {
        //...
        authCtx.setToken(data.token);
        authCtx.setRole(data.role);
        authCtx.setUser(data.user);
        navigate('/diagnostics');
        setServerErrors([]);
      } else {
        setServerErrors(['Грешка.']);
      }
    }).catch(errors => {
      setServerErrors(errors.errors);
    }).finally(() => {
      setIsSubmitting(false);
    }
    
    );
  }

  return (
    <div className='lists'>
      {isSubmitting && <LoadingSpinner />}
      <section className={classes.auth}>
        <h1>Вход</h1>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <p className={classes.control}>
              <label>E-mail:</label>
              <input {...register("email", { required: true, pattern: /\S+@\S+\.\S+/ })}
                  className={errors.email ? classes['error-input'] : ""}
                  id="email"
                  type="email"
              />
          </p>
          {errors.email && <p className={classes['error-message']}>Моля, въведете валиден e-mail.</p>}
          <p className={classes.control}>
              <label>Парола:</label>
              <input {...register("password", { required: true })}
                  className={errors.password ? classes['error-input'] : ""}
                  id="password"
                  type="password"
              />
          </p>
          {errors.password && <p className={classes['error-message']}>Моля, въведете парола.</p>}
          <div className={classes.action}>
            <button>Вход</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AuthForm;