const selectCustomStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'white' : '#666666',
      backgroundColor: state.isFocused ? '#2192c7' : 'white',
      ':hover': {
        backgroundColor: '#2192c7',
        color: 'white'
      },
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ddd',
      ':hover': {
        border: '1px solid #2192c7',
      },
    }),
  };

  export default selectCustomStyles;