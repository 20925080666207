import { useContext } from 'react';
import AuthContext, { getToken } from '../../store/auth-context';
import AuthForm from './AuthForm';
import { useNavigation } from 'react-router-dom';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import MessageCard from '../../components/UI/MessageCard';

const AuthPage = () => {
  const navigation = useNavigation();

  const authCtx = useContext(AuthContext);

  return (
    <>
      {/* {navigation.state === 'submitting' && <LoadingSpinner />} */}
      {authCtx.isLoggedIn && navigation.state != 'loading' ? <div className='lists'><MessageCard message="Вече сте влезли в системата." /></div> : <AuthForm />}
    </>
  );
};

export default AuthPage;