import { useState } from 'react';
import { Outlet, useNavigation } from 'react-router-dom';
import MainNavigation from '../components/Layout/MainNavigation';
import ErrorCard from '../components/UI/ErrorCard';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const RootLayout = () => {
    const navigation = useNavigation();

    const [serverErrors, setServerErrors] = useState([]);

    return (
        <>
            <MainNavigation />
            <main>
                {navigation.state === 'loading' && <LoadingSpinner />}
                <div className='lists'>{serverErrors && serverErrors.length > 0 && <ErrorCard errors={serverErrors} />}</div>
                <Outlet context={[serverErrors, setServerErrors]} />
            </main>
        </>
    );
};

export default RootLayout;