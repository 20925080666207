export const rightFront = {
    f1: { key: "f1",
        d: "M227,0c0,0-21.3-0.9-31.7,12.8c-2,2.7-3.4,5.7-4.3,9c6.5,12.8,5.7,29.3,18.6,38.1 c6.4,4.4,15.1,1.1,18.8-5.7c0-33.1,0-54.2,0-54.2L227,0z",
        fill: "#b2b2b2" },
    f2: { key: "f2",
        d: "M216.4,92.9c3.2,2.2,7.5,3.1,11.9,3.5c0-15.5,0-29.7,0-42.2c-3.7,6.8-12.4,10.1-18.8,5.7 c-12.9-8.8-12-25.2-18.6-38.1c0,0.1,0,0.2-0.1,0.2c1.2,3,2.4,5.5,3,8.5C199,52.3,197.1,79.2,216.4,92.9z",
        fill: "#b2b2b2" },
    f3: { key: "f3",
        d: "M191.2,51.6c-1.2-0.4-4.1-0.9-5.5,1c-2.8,3.9-0.1,7.3,2,14.6c1.7,6,1.7,7.9,5,10.2 c0.9,0.6,2.6,0.4,3.2,0.2C196.8,68.8,193.9,60,191.2,51.6z",
        fill: "#b2b2b2" },
    f4: { key: "f4",
        d: "M228.4,112.5c0-5.5,0-10.9,0-16.2c-4.5-0.4-8.7-1.3-11.9-3.5C197.1,79.2,199,52.3,194,30.5 c-0.6-3-1.8-5.5-3-8.5c-3.6,13.5,0.9,29.8,0.9,29.8s-0.2-0.1-0.6-0.2c2.6,8.4,5.5,17.2,4.7,26c0.2,0,0.3-0.1,0.3-0.1s0.6,2,1.3,5.1 C202.8,96.9,213.1,110,228.4,112.5z",
        fill: "#b2b2b2" },
    f5: { key: "f5",
        d: "M228.4,141.6c0-10,0-19.7,0-29.1c-15.3-2.5-25.6-15.6-30.9-29.9c1.8,7.7,4.8,22.3,3.1,29.8 c-1.5,6.5-3.4,12.9-11.1,17.6C200.4,138,215,141.6,228.4,141.6z",
        fill: "#b2b2b2" },
    f6: { key: "f6",
        d: "M228.4,193.4c0-17.7,0-35.1,0-51.8c-13.4,0-28-3.6-39-11.6c-4.7,2.9-11.6,5.2-21.8,6.4 c-26.9,3.3-32.5,14.9-39.5,22.9c-1,1.1-1.9,2.7-2.8,4.7c14.8,13,34.6,18.5,53.6,23.7C195.5,192,211.9,194.4,228.4,193.4z",
        fill: "#b2b2b2" },
    f7: { key: "f7",
        d: "M179,187.7c-7-1.9-14.1-3.8-21.1-6.2c0.4,12.1-0.6,24.3-3.8,36.5c0.1,7.7,0.8,32.2,5.2,47.2 c5.2,18,7.6,36.4,6.6,42.1c-0.9,5.7-1.4,16.5-2.8,25.1c-0.5,3.2-0.8,5.7-1.1,8.1c0,0,0.1,0,0.1,0c10,2.5,20.6,0.6,28.1,7.5 c11.3,10.7,21.5,26.5,38.1,26.9c0-50.5,0-117.8,0-181.5C211.9,194.4,195.5,192,179,187.7z",
        fill: "#b2b2b2" },
    f8: { key: "f8",
        d: "M131,206.4c1.2-2.5,2.5-5.6,3.1-8.1c1.3-8.5,4.6-16,6.6-24c-5.5-2.8-10.6-6.2-15.3-10.3 c-4.9,11-8.8,32.9-12.4,39.7c-4.3,8-10.4,26.9-11.8,34.5C100.3,243,98,250,96,255.9C121.8,260.9,123.8,224.4,131,206.4z",
        fill: "#b2b2b2" },
    f9: { key: "f9",
        d: "M158,181.5c-6-2-11.8-4.3-17.3-7.2c-2,8-5.2,15.5-6.6,24c-0.6,2.5-1.9,5.6-3.1,8.1 c-3.7,9.3-6.1,23.5-11,34.4c3.4,13.6,4.9,26.9,6,41.1c0,0.1,0,0.3,0,0.4c0.8-3,2-6.2,4-10c9.7-18,24.1-56.7,24.1-56.7s0,0.9,0,2.4 C157.3,205.8,158.4,193.6,158,181.5z",
        fill: "#b2b2b2" },
    f10: { key: "f10",
        d: "M126,281.9c-1-13.8-2.5-26.8-5.7-39.9c-2.8,10.1-7.2,19.3-9.9,29.3c-1.2,3.7-1.9,7.5-3.7,10.6 c-5.6,13.1-13.7,25-19.3,38.1c-8.1,18.7-15,36.8-26.2,54.3c-5.4,9.2,1.8,14.1,8.3,18.9c0-0.2,0.1-0.5,0.1-0.7 c0.9-7.1,6.2-22.3,14.5-32.8c11.3-14.3,25.7-26.8,34.2-47.6c6.2-15.1,5.7-21.9,7.8-29.7C126,282.2,126,282,126,281.9z",
        fill: "#b2b2b2" },
    f11: { key: "f11",
        d: "M120,240.8c-4.6,10.2-11.5,17.5-24,15c-1.2,3.7-2.3,6.9-2.9,8.9c-1.4,5.2-12.5,18-17.7,26.9 c-5.2,9-11.1,44.4-18.2,55.8c-6.9,11.1-11.6,21.2-15.8,22.6c3.9,5.8,10.7,9.4,17.8,10c0-1.8,0.6-3.7,1.9-5.9 c11.2-17.5,18.1-35.6,26.2-54.3c5.6-13.1,13.7-25,19.3-38.1c1.9-3.1,2.5-6.9,3.7-10.6c2.6-10,7.1-19.2,9.9-29.3 C120.2,241.6,120.1,241.2,120,240.8z",
        fill: "#b2b2b2" },
    f12: { key: "f12",
        d: "M23.7,382.3c7.4-1.3,13.3-7.2,20.2-9.3c-0.9-0.9-1.7-1.9-2.4-2.9c-0.1,0-0.2,0.1-0.3,0.1 c0,0-14.2,3.3-23.2,11.8c-0.2,0.2-0.5,0.4-0.7,0.7C19.2,383.6,21.8,382.8,23.7,382.3z",
        fill: "#b2b2b2" },
    f13: { key: "f13",
        d: "M51.8,397.9c3.2-5.1,6.1-10.1,7.6-16c-0.1-0.6-0.2-1.2-0.2-1.8c-5.8-0.5-11.4-3-15.4-7.1 c-6.9,2.2-12.8,8-20.2,9.3c-1.9,0.5-4.4,1.3-6.4,0.4c-8.6,7.9-14.9,7.9-14.4,10.7c0.5,2.8,5.7,4.7,11.3,2.8c5.7-1.9,6.6-5.7,9-5.2 c2.4,0.5-9,17-12.8,21.3s-13.3,16.6-8.5,18.4c5.8,2.2,15.4-12.1,16.4-13.5c0.9-1.2,2.3-2.8,2.8-2.6c2.5,1.1-12.4,17.7-11.7,25.1 c0.2,2,5.2,6.1,11.8-5.7c6.6-11.8,8.9-15.2,9.9-15.1c2.5,0.2-6.3,18.2-7.2,21.3c-0.5,1.7-0.2,4.4,1.1,5.4c1-1,1.9-2.2,2.3-3.3 C35.5,427.3,42.4,412.9,51.8,397.9z",
        fill: "#b2b2b2" },
    f14: { key: "f14",
        d: "M59.4,382c-1.5,5.8-4.4,10.9-7.6,16c-9.4,15-16.2,29.3-24.3,44.3c-0.4,1.2-1.3,2.3-2.3,3.3 c1.1,0.8,2.8,0.5,5.3-2.2c5.7-6.1,8.7-21.7,11.7-20.9c2.9,0.8-3.9,12.1-3.9,14.9c0,2.8,1.6,9.2,7.6-0.9c5.2-8.9,5.2-14.2,10.9-21.3 c5.5-6.8,11.4-15,12.6-22C65,389.9,60.3,386.6,59.4,382z",
        fill: "#b2b2b2" },
    f15: { key: "f15",
        d: "M176.5,358c15,16.7,30.5,29.9,51.9,33c0-5.1,0-10.5,0-16.1c-16.6-0.3-26.8-16.2-38.1-26.9 c-7.5-6.9-18.1-5-28.1-7.5c0,0-0.1,0-0.1,0c-0.5,3.7-1,7-2.6,11.3C166,352.1,172.7,353.6,176.5,358z",
        fill: "#b2b2b2" },
    f16: { key: "f16",
        d: "M197.1,399.2c10.8-2.9,20.6-4.7,31.3-4.5c0-1.2,0-2.5,0-3.7c-21.3-3.1-36.8-16.3-51.9-33 c-3.8-4.4-10.5-5.9-17.1-6.2c-0.2,0.4-0.3,0.9-0.5,1.4c-0.1,0.2-0.2,0.5-0.3,0.8c2.5,5,5.9,9.4,9.8,13.4 C177.8,379.2,181.5,402.9,197.1,399.2z",
        fill: "#b2b2b2" },
    f17: { key: "f17",
        d: "M172.8,401.1c6.9,9.4,24.3-1.2,27.4,11.2c3.3,14.8,13.8,27.2,28.2,26.2c0-10.5,0-25.6,0-43.7 c-10.7-0.2-20.4,1.6-31.3,4.5c-15.6,3.7-19.3-20-28.7-31.8c-3.9-3.9-7.4-8.4-9.8-13.4c0,0,0,0.1-0.1,0.1c2.1,7.2,3.8,14.1,4.9,22 C164.7,384.8,167.2,394.2,172.8,401.1z",
        fill: "#b2b2b2" },
    f18: { key: "f18",
        d: "M173.4,508.4c4.4-35.6,1.9-69.9-1.9-105.4c-0.5-3.7-3-7.1-4-10.8c-2.1-5.1-3.3-10.6-4.1-16 c-1.1-7.9-2.8-14.7-4.9-22c-3.8,10.5-15.9,59.3-7.7,113.6c8.5,56.1,13.7,59.9,16.1,65.1c0.4,0.9,0.8,2.2,1.1,3.6 C174.5,530.9,172.2,516.7,173.4,508.4z",
        fill: "#b2b2b2" },
    f19: { key: "f19",
        d: "M221.4,455.3c-11.6,25-10.7,54.7,2.1,79.3c0.2-18.1,0.2-38.6,1.3-45.4c1.7-10.7,3.5-28.5,3.5-33.9 c0-0.4,0-3.1,0-7.8C225.5,449.5,222.6,453,221.4,455.3z",
        fill: "#b2b2b2" },
    f20: { key: "f20",
        d: "M223.2,557.8c0.2-5.8,0.3-14.2,0.4-23.2c-12.8-24.6-13.7-54.3-2.1-79.3c1.2-2.4,4-5.8,6.9-7.8 c0-2.5,0-5.5,0-9.1c-14.4,1-24.9-11.4-28.2-26.2c-3.1-12.5-20.6-1.9-27.4-11.2c-2.2-2.7-3.9-5.7-5.2-9c1,3.8,3.5,7.1,4,10.8 c3.7,35.6,6.2,69.9,1.9,105.4c-1.2,8.4,1.1,22.5-5.4,28.1c0.2,1.1,0.4,2.2,0.5,3.5C179.9,557.7,203.8,563.5,223.2,557.8z",
        fill: "#b2b2b2" },
    f21: { key: "f21",
        d: "M223,560.6c0.1-0.8,0.1-1.8,0.1-2.8c-10.1,3-21.3,2.8-31.4-0.5c3.1,58.9,7.5,117.3,17.8,177.5 c2.1,13.1,2.9,27.1,9,38.7c0-0.8,0-1.6,0-2.5c-0.7-9.7-3.9-19-1.7-27.5c2.2-8.5,3.3-15.2,2.6-17.5c0,0-5.2-9.1-5-16.2 c0.2-7.1,2.2-30.1,3.6-37c1.4-6.9,3.3-34.2,1.7-43.5c-1.6-9.3-6.1-20.7-4.4-31.5C217.9,582.1,222.1,575.7,223,560.6z",
        fill: "#b2b2b2" },
    f22: { key: "f22",
        d: "M191.7,557.3c-9.4-3.1-17.8-8.8-23.2-17.4c0.7,6.2,0.6,14.5,0.3,21.3c-0.5,9.9,2.4,14.2,1.9,26 c-0.5,11.8-7.1,38.3-4.3,57.7c1.7,11.7,7.4,32.1,11.8,47.5c5.4-10.6,13.6-19.3,21.6-28.4C196,628.3,193.6,592.9,191.7,557.3z",
        fill: "#b2b2b2" },
    f23: { key: "f23",
        d: "M209.6,734.8c-4.1-23.8-7.2-47.4-9.8-70.8c-8,9.1-16.2,17.8-21.6,28.4c2.9,10.2,5.3,18.2,5.7,20.1 c0.3,1.5,0.3,3.1,0.3,4.7c13,20.6,0.2,46.7-17.5,60.8c0.1,1.6,0.5,2.2,0.5,2.2s-0.4,1.6,0.5,3.1c0.8,1.4,3.1,1.8,3.1,1.8 s0.6,2,1.9,2.5c2.1,0.7,3.2,0.4,3.2,0.4s-0.3,1.8,2,3.4c1.8,1.3,4.1,0.8,4.1,0.8s-0.1,2.4,1.7,3.3c1.8,0.8,3.2,0.8,4.1,0.7 c5.6-9.4,10.5-20.1,18.5-27.6c3.3,12-5.9,22.1-14.1,30.7c0.6,0.3,1.4,0.5,2.2,0.6c8.4,1.1,11.1-4.1,14.8-8.6 c3.3-4.1,8.8-9.5,9.2-17.8C212.5,761.9,211.7,747.9,209.6,734.8z",
        fill: "#b2b2b2" },
    f24: { key: "f24",
        d: "M184.2,717.2c-0.1,3.7-0.7,7.7-0.2,11.2c0.3,1.9,1.3,7.7-0.1,11.5s-5.7,15.6-9.5,21.7 c-3.8,6.1-6.8,9-7.6,14.1c-0.1,0.9-0.2,1.7-0.1,2.3C184.5,764,197.2,737.8,184.2,717.2z",
        fill: "#b2b2b2" },
    f25: { key: "f25",
        d: "M206.5,768.5c-8.1,7.5-12.9,18.2-18.5,27.6c0.6-0.1,0.9-0.2,0.9-0.2s1.1,2.2,3.5,3.4 C200.6,790.6,209.7,780.5,206.5,768.5z",
        fill: "#b2b2b2" },
};

export const leftFront = {
    f1: { key: "f1",
        d: "M2,0c0,0,21.3-0.9,31.7,12.8c2,2.7,3.4,5.7,4.3,9C31.4,34.6,32.3,51,19.4,59.8C13,64.2,4.3,60.9,0.6,54.2c0-33.1,0-54.2,0-54.2L2,0z",
        fill: "#b2b2b2" },
    f2: { key: "f2",
        d: "M12.6,92.9C9.3,95,5.1,95.9,0.6,96.3c0-15.5,0-29.7,0-42.2c3.7,6.8,12.4,10.1,18.8,5.7C32.3,51,31.4,34.6,38,21.8c0,0.1,0,0.2,0.1,0.2c-1.2,3-2.4,5.5-3,8.5C30,52.3,31.9,79.2,12.6,92.9z",
        fill: "#b2b2b2" },
    f3: { key: "f3",
        d: "M37.8,51.6c1.2-0.4,4.1-0.9,5.5,1c2.8,3.9,0.1,7.3-2,14.6c-1.7,6-1.7,7.9-5,10.2c-0.9,0.6-2.6,0.4-3.2,0.2C32.2,68.8,35.1,60,37.8,51.6z",
        fill: "#b2b2b2" },
    f4: { key: "f4",
        d: "M0.6,112.5c0-5.5,0-10.9,0-16.2c4.5-0.4,8.7-1.3,11.9-3.5C31.9,79.2,30,52.3,35,30.5c0.6-3,1.8-5.5,3-8.5c3.6,13.5-0.9,29.8-0.9,29.8s0.2-0.1,0.6-0.2c-2.6,8.4-5.5,17.2-4.7,26c-0.2,0-0.3-0.1-0.3-0.1s-0.6,2-1.3,5.1C26.2,96.9,15.9,110,0.6,112.5z",
        fill: "#b2b2b2" },
    f5: { key: "f5",
        d: "M0.6,141.6c0-10,0-19.7,0-29.1c15.3-2.5,25.6-15.6,30.9-29.9c-1.8,7.7-4.8,22.3-3.1,29.8c1.5,6.5,3.4,12.9,11.1,17.6C28.6,138,14,141.6,0.6,141.6z",
        fill: "#b2b2b2" },
    f6: { key: "f6",
        d: "M0.6,193.4c0-17.7,0-35.1,0-51.8c13.4,0,28-3.6,39-11.6c4.7,2.9,11.6,5.2,21.8,6.4c26.9,3.3,32.5,14.9,39.5,22.9c1,1.1,1.9,2.7,2.8,4.7C88.8,177,69,182.6,50,187.7C33.5,192,17.1,194.4,0.6,193.4z",
        fill: "#b2b2b2" },
    f7: { key: "f7",
        d: "M50,187.7c7-1.9,14.1-3.8,21.1-6.2c-0.4,12.1,0.6,24.3,3.8,36.5c-0.1,7.7-0.8,32.2-5.2,47.2c-5.2,18-7.6,36.4-6.6,42.1c0.9,5.7,1.4,16.5,2.8,25.1c0.5,3.2,0.8,5.7,1.1,8.1c0,0-0.1,0-0.1,0c-10,2.5-20.6,0.6-28.1,7.5c-11.3,10.7-21.5,26.5-38.1,26.9c0-50.5,0-117.8,0-181.5C17.1,194.4,33.5,192,50,187.7z",
        fill: "#b2b2b2" },
    f8: { key: "f8",
        d: "M98,206.4c-1.2-2.5-2.5-5.6-3.1-8.1c-1.3-8.5-4.6-16-6.6-24c5.5-2.8,10.6-6.2,15.3-10.3c4.9,11,8.8,32.9,12.4,39.7c4.3,8,10.4,26.9,11.8,34.5c0.9,4.7,3.2,11.7,5.2,17.6C107.2,260.9,105.2,224.4,98,206.4z",
        fill: "#b2b2b2" },
    f9: { key: "f9",
        d: "M71,181.5c6-2,11.8-4.3,17.3-7.2c2,8,5.2,15.5,6.6,24c0.6,2.5,1.9,5.6,3.1,8.1c3.7,9.3,6.1,23.5,11,34.4c-3.4,13.6-4.9,26.9-6,41.1c0,0.1,0,0.3,0,0.4c-0.8-3-2-6.2-4-10c-9.7-18-24.1-56.7-24.1-56.7s0,0.9,0,2.4C71.7,205.8,70.6,193.6,71,181.5z",
        fill: "#b2b2b2" },
    f10: { key: "f10",
        d: "M103,281.9c1-13.8,2.5-26.8,5.7-39.9c2.8,10.1,7.2,19.3,9.9,29.3c1.2,3.7,1.9,7.5,3.7,10.6c5.6,13.1,13.7,25,19.3,38.1c8.1,18.7,15,36.8,26.2,54.3c5.4,9.2-1.8,14.1-8.3,18.9c0-0.2-0.1-0.5-0.1-0.7c-0.9-7.1-6.2-22.3-14.5-32.8c-11.3-14.3-25.7-26.8-34.2-47.6c-6.2-15.1-5.7-21.9-7.8-29.7C103,282.2,103,282,103,281.9z",
        fill: "#b2b2b2" },
    f11: { key: "f11",
        d: "M109,240.8c4.6,10.2,11.5,17.5,24,15c1.2,3.7,2.3,6.9,2.9,8.9c1.4,5.2,12.5,18,17.7,26.9c5.2,9,11.1,44.4,18.2,55.8c6.9,11.1,11.6,21.2,15.8,22.6c-3.9,5.8-10.7,9.4-17.8,10c0-1.8-0.6-3.7-1.9-5.9c-11.2-17.5-18.1-35.6-26.2-54.3c-5.6-13.1-13.7-25-19.3-38.1c-1.9-3.1-2.5-6.9-3.7-10.6c-2.6-10-7.1-19.2-9.9-29.3C108.8,241.6,108.9,241.2,109,240.8z",
        fill: "#b2b2b2" },
    f12: { key: "f12",
        d: "M205.3,382.3c-7.4-1.3-13.3-7.2-20.2-9.3c0.9-0.9,1.7-1.9,2.4-2.9c0.1,0,0.2,0.1,0.3,0.1c0,0,14.2,3.3,23.2,11.8c0.2,0.2,0.5,0.4,0.7,0.7C209.8,383.6,207.2,382.8,205.3,382.3z",
        fill: "#b2b2b2" },
    f13: { key: "f13",
        d: "M177.2,397.9c-3.2-5.1-6.1-10.1-7.6-16c0.1-0.6,0.2-1.2,0.2-1.8c5.8-0.5,11.4-3,15.4-7.1c6.9,2.2,12.8,8,20.2,9.3c1.9,0.5,4.4,1.3,6.4,0.4c8.6,7.9,14.9,7.9,14.4,10.7c-0.5,2.8-5.7,4.7-11.3,2.8c-5.7-1.9-6.6-5.7-9-5.2c-2.4,0.5,9,17,12.8,21.3s13.3,16.6,8.5,18.4c-5.8,2.2-15.4-12.1-16.4-13.5c-0.9-1.2-2.3-2.8-2.8-2.6c-2.5,1.1,12.4,17.7,11.7,25.1c-0.2,2-5.2,6.1-11.8-5.7c-6.6-11.8-8.9-15.2-9.9-15.1c-2.5,0.2,6.3,18.2,7.2,21.3c0.5,1.7,0.2,4.4-1.1,5.4c-1-1-1.9-2.2-2.3-3.3C193.5,427.3,186.6,412.9,177.2,397.9z",
        fill: "#b2b2b2" },
    f14: { key: "f14",
        d: "M169.6,382c1.5,5.8,4.4,10.9,7.6,16c9.4,15,16.2,29.3,24.3,44.3c0.4,1.2,1.3,2.3,2.3,3.3c-1.1,0.8-2.8,0.5-5.3-2.2c-5.7-6.1-8.7-21.7-11.7-20.9c-2.9,0.8,3.9,12.1,3.9,14.9c0,2.8-1.6,9.2-7.6-0.9c-5.2-8.9-5.2-14.2-10.9-21.3c-5.5-6.8-11.4-15-12.6-22C164,389.9,168.7,386.6,169.6,382z",
        fill: "#b2b2b2" },
    f15: { key: "f15",
        d: "M52.5,358c-15,16.7-30.5,29.9-51.9,33c0-5.1,0-10.5,0-16.1c16.6-0.3,26.8-16.2,38.1-26.9c7.5-6.9,18.1-5,28.1-7.5c0,0,0.1,0,0.1,0c0.5,3.7,1,7,2.6,11.3C63,352.1,56.3,353.6,52.5,358z",
        fill: "#b2b2b2" },
    f16: { key: "f16",
        d: "M31.9,399.2c-10.8-2.9-20.6-4.7-31.3-4.5c0-1.2,0-2.5,0-3.7c21.3-3.1,36.8-16.3,51.9-33c3.8-4.4,10.5-5.9,17.1-6.2c0.2,0.4,0.3,0.9,0.5,1.4c0.1,0.2,0.2,0.5,0.3,0.8c-2.5,5-5.9,9.4-9.8,13.4C51.2,379.2,47.5,402.9,31.9,399.2z",
        fill: "#b2b2b2" },
    f17: { key: "f17",
        d: "M56.2,401.1c-6.9,9.4-24.3-1.2-27.4,11.2c-3.3,14.8-13.8,27.2-28.2,26.2c0-10.5,0-25.6,0-43.7c10.7-0.2,20.4,1.6,31.3,4.5c15.6,3.7,19.3-20,28.7-31.8c3.9-3.9,7.4-8.4,9.8-13.4c0,0,0,0.1,0.1,0.1c-2.1,7.2-3.8,14.1-4.9,22C64.3,384.8,61.8,394.2,56.2,401.1z",
        fill: "#b2b2b2" },
    f18: { key: "f18",
        d: "M55.6,508.4c-4.4-35.6-1.9-69.9,1.9-105.4c0.5-3.7,3-7.1,4-10.8c2.1-5.1,3.3-10.6,4.1-16c1.1-7.9,2.8-14.7,4.9-22c3.8,10.5,15.9,59.3,7.7,113.6c-8.5,56.1-13.7,59.9-16.1,65.1c-0.4,0.9-0.8,2.2-1.1,3.6C54.5,530.9,56.8,516.7,55.6,508.4z",
        fill: "#b2b2b2" },
    f19: { key: "f19",
        d: "M7.6,455.3c11.6,25,10.7,54.7-2.1,79.3c-0.2-18.1-0.2-38.6-1.3-45.4c-1.7-10.7-3.5-28.5-3.5-33.9c0-0.4,0-3.1,0-7.8C3.5,449.5,6.4,453,7.6,455.3z",
        fill: "#b2b2b2" },
    f20: { key: "f20",
        d: "M5.8,557.8c-0.2-5.8-0.3-14.2-0.4-23.2c12.8-24.6,13.7-54.3,2.1-79.3c-1.2-2.4-4-5.8-6.9-7.8c0-2.5,0-5.5,0-9.1c14.4,1,24.9-11.4,28.2-26.2c3.1-12.5,20.6-1.9,27.4-11.2c2.2-2.7,3.9-5.7,5.2-9c-1,3.8-3.5,7.1-4,10.8c-3.7,35.6-6.2,69.9-1.9,105.4c1.2,8.4-1.1,22.5,5.4,28.1c-0.2,1.1-0.4,2.2-0.5,3.5C49.1,557.7,25.2,563.5,5.8,557.8z",
        fill: "#b2b2b2" },
    f21: { key: "f21",
        d: "M6,560.6c-0.1-0.8-0.1-1.8-0.1-2.8c10.1,3,21.3,2.8,31.4-0.5c-3.1,58.9-7.5,117.3-17.8,177.5c-2.1,13.1-2.9,27.1-9,38.7c0-0.8,0-1.6,0-2.5c0.7-9.7,3.9-19,1.7-27.5C9.8,735,8.8,728.2,9.5,726c0,0,5.2-9.1,5-16.2c-0.2-7.1-2.2-30.1-3.6-37c-1.4-6.9-3.3-34.2-1.7-43.5c1.6-9.3,6.1-20.7,4.4-31.5C11.1,582.1,6.9,575.7,6,560.6z",
        fill: "#b2b2b2" },
    f22: { key: "f22",
        d: "M37.3,557.3c9.4-3.1,17.8-8.8,23.2-17.4c-0.7,6.2-0.6,14.5-0.3,21.3c0.5,9.9-2.4,14.2-1.9,26c0.5,11.8,7.1,38.3,4.3,57.7c-1.7,11.7-7.4,32.1-11.8,47.5c-5.4-10.6-13.6-19.3-21.6-28.4C33,628.3,35.4,592.9,37.3,557.3z",
        fill: "#b2b2b2" },
    f23: { key: "f23",
        d: "M19.4,734.8c4.1-23.8,7.2-47.4,9.8-70.8c8,9.1,16.2,17.8,21.6,28.4c-2.9,10.2-5.3,18.2-5.7,20.1c-0.3,1.5-0.3,3.1-0.3,4.7c-13,20.6-0.2,46.7,17.5,60.8c-0.1,1.6-0.5,2.2-0.5,2.2s0.4,1.6-0.5,3.1c-0.8,1.4-3.1,1.8-3.1,1.8s-0.6,2-1.9,2.5c-2.1,0.7-3.2,0.4-3.2,0.4s0.3,1.8-2,3.4c-1.8,1.3-4.1,0.8-4.1,0.8s0.1,2.4-1.7,3.3c-1.8,0.8-3.2,0.8-4.1,0.7c-5.6-9.4-10.5-20.1-18.5-27.6c-3.3,12,5.9,22.1,14.1,30.7c-0.6,0.3-1.4,0.5-2.2,0.6c-8.4,1.1-11.1-4.1-14.8-8.6c-3.3-4.1-8.8-9.5-9.2-17.8C16.5,761.9,17.3,747.9,19.4,734.8z",
        fill: "#b2b2b2" },
    f24: { key: "f24",
        d: "M44.8,717.2c0.1,3.7,0.7,7.7,0.2,11.2c-0.3,1.9-1.3,7.7,0.1,11.5c1.4,3.8,5.7,15.6,9.5,21.7c3.8,6.1,6.8,9,7.6,14.1c0.1,0.9,0.2,1.7,0.1,2.3C44.5,764,31.8,737.8,44.8,717.2z",
        fill: "#b2b2b2" },
    f25: { key: "f25",
        d: "M22.5,768.5c8.1,7.5,12.9,18.2,18.5,27.6c-0.6-0.1-0.9-0.2-0.9-0.2s-1.1,2.2-3.5,3.4C28.4,790.6,19.3,780.5,22.5,768.5z",
        fill: "#b2b2b2" },
};

export const rightBack = {
    b1: { key: "b1",
        d: "M30.9,10.6C19.2-1.2,0.9,0,0.9,0h-1.2c0,0,0,22.6,0,57.5c2,0.4,3.9,0.7,5.5,0.7C29,60.4,30.1,31.1,30.9,10.6z",
        fill: "#b2b2b2" },
    b2: { key: "b2",
        d: "M13.3,84.2c13.9-6.6,16-24.4,24.7-36.2c0.9-3.8,4.8-23.5-5.6-35.7c-0.5-0.6-1-1.1-1.5-1.6c-0.7,17.8-1.6,42.4-17.6,46.9C13.2,66.3,12.7,75.5,13.3,84.2z",
        fill: "#b2b2b2" },
    b3: { key: "b3",
        d: "M27.6,90.9c0.2-1.2,0.4-2.2,0.6-3.1c1.4-6.1,5.4-12.2,5.4-12.2s0,0,0.7,0.5c0.7,0.5,1.9-1.9,3.1-4c1.2-2.1,4.7-11.3,4.9-18.3c0.2-7-4.5-5.2-4.5-5.2s0.1-0.2,0.2-0.6c-8.7,11.8-10.8,29.6-24.7,36.2C16.8,88.6,22.1,91.1,27.6,90.9z",
        fill: "#b2b2b2" },
    b4: { key: "b4",
        d: "M30.9,123.5c-2.8-3.5-4.3-7.1-4.6-9.2c-0.6-3.8,0.2-16.2,1.3-23.5c-5.5,0.2-10.8-2.3-14.3-6.7C13.9,98.6,9.8,125.8,30.9,123.5z",
        fill: "#b2b2b2" },
    b5: { key: "b5",
        d: "M74.6,172.8c0-0.1,0-0.2,0.1-0.2c-16.4-7.5-32.3-15.6-43.4-31.3c-5.6-7.4-9.3-15.5-14.2-23.5c-0.1-0.4-0.3-0.7-0.4-1.1c-4.4-8.6-2.9-23.1-3.3-32.3c-0.6-8.7-0.1-17.9,0-26.6c-2.3,0.7-5,0.9-8,0.6c-1.6,0-3.4-0.3-5.5-0.7c0,93.5,0,275.5,0,346.8c20.7-12.9,48.2-4.3,65.5,11.9c0.4,1.7,0.8,3.4,1.2,5c0.2-0.8,0.3-1.7,0.5-2.5c-1.9-23.9-6.1-48.2-18.8-68.9c-0.5,0-1-0.1-1.5,0.1c-4.3-40.9-2.5-82.4,8.1-122.6C59.7,208.1,64,190.1,74.6,172.8z",
        fill: "#b2b2b2" },
    b6: { key: "b6",
        d: "M31.2,141.2c14.9,21.1,38.4,28.5,60.1,39c5.2,2.6,11.1,3.7,17.2,4.6c-0.3-1.8-0.6-3.6-0.8-5.5c-1.2-9.9-8.7-23.7-21.1-33.6c-12.4-9.9-25.3-11.3-39.4-13.1c-7.6-1-12.9-5-16.3-9.1c-7.6,0.8-11.9-2.2-14.4-7c0.2,0.4,0.3,0.7,0.4,1.1C21.9,125.7,25.6,133.8,31.2,141.2z",
        fill: "#b2b2b2" },
    b7: { key: "b7",
        d: "M91.3,180.2c-5.5-2.7-11.1-5.1-16.7-7.7c0,0.1,0,0.2-0.1,0.2c-10.5,17.3-14.9,35.3-19.8,54.5c-10.5,40.3-12.4,81.8-8.1,122.6c1.9-0.6,4.3,1.2,6.2-1.2c3.4-3.4,7.1-6.7,10.6-10c-0.4-5.5,1.3-10.9,1.3-18c0-9.4,0-13.1-0.9-24.9c-0.9-11.7,4.7-20.2,9.2-31s5.9-39.9,5.9-39.9s0.2,0.6,0.5,1.6c1.3-6.3,5.1-12.3,7-19c0.1,0.2,0.1,0.4,0.2,0.6c2.6-8.8,4.5-18,7.2-26.8C92.9,181,92.1,180.6,91.3,180.2z",
        fill: "#b2b2b2" },
    b8: { key: "b8",
        d: "M103.7,255.2c-5-16.1-13-31-17.3-47.7c-2,6.7-5.7,12.7-7,19c1.3,4.6,5.1,17.6,7.1,21.9c2.3,5.2,11.5,19.7,14.3,25.1c0.2,0.3,0.3,0.7,0.5,1.1c0.8-2.5,1.9-4.9,3.1-7.1C106.2,263.8,104.9,258.9,103.7,255.2z",
        fill: "#b2b2b2" },
    b9: { key: "b9",
        d: "M121.1,248.2c-14.4-19.7-20.8-42.5-27.9-65.1c-2.4,8.2-4.3,16.7-6.7,25c4.4,16.5,12.3,31.2,17.2,47.1c0.8,2.4,1.6,5.3,1.6,8.1C112,260,118.4,255.1,121.1,248.2z",
        fill: "#b2b2b2" },
    b10: { key: "b10",
        d: "M131.1,256.2c-3.2-8.9-5.1-18.5-6.3-24c-1.6-7.5-10.6-26.8-12-30.7c-1.1-3.2-3-9.2-4.4-16.6c-5.2-0.8-10.2-1.7-14.8-3.5c-0.2,0.6-0.4,1.2-0.5,1.8c7.2,22.8,13.6,45.9,28.4,65.8C124.1,252.2,127.4,254.5,131.1,256.2z",
        fill: "#b2b2b2" },
    b11: { key: "b11",
        d: "M176.8,370.4c0.9-0.7,1.4-1.7,1.7-2.8c-12.9-10.5-19.4-25.8-26.5-39.9c-1.2-2.5-1.9-6.2-2.5-8.7c-5.6-13.5-15.4-31.2-9.1-45.4c-2-2.8-3.9-5.6-5.4-8.5c-1.5-2.7-2.7-5.7-3.8-8.8c-3.7-1.7-7-4-9.5-7.3c-0.2-0.3-0.4-0.5-0.6-0.8c-2.7,6.9-9,11.8-15.8,15c0,0.6-0.1,1.2-0.2,1.9c0.7,2.7,1.4,5.4,2.9,8c9.3,14.2,18,27.9,25.4,42.7c9.3,19.2,20.4,36.5,30.3,55.1c1,1.8,0.6,3.3-0.6,4.6C168,376.7,173,373.7,176.8,370.4z",
        fill: "#b2b2b2" },
    b12: { key: "b12",
        d: "M149.5,319c0.6,2.5,1.2,6.2,2.5,8.7c7.4,14.9,14.2,31,28.5,41.5c5.1,3.9,11.2,4.5,17.1,3c-3.7-1.7-7-2.5-9.1-3.1c-4.8-1.4-8-5.4-10.6-9.9c-2.6-4.5-10.3-17.8-12.9-23.2c-2.6-5.4-8.7-36.1-8.7-36.1c-4.3-11.2-10.6-18.9-15.9-26.3C134.1,287.8,143.9,305.4,149.5,319z",
        fill: "#b2b2b2" },
    b13: { key: "b13",
        d: "M163.8,371c-9.9-18.6-21.1-35.9-30.3-55.1c-7.4-14.9-16.1-28.5-25.4-42.7c-1.5-2.7-2.3-5.4-2.9-8c-0.1,0.9-0.4,1.7-0.8,2.5c-1.2,2.1-2.3,4.6-3.1,7.1c2.5,6.3,3.9,22,6.1,31.7c2.3,10.3,17.1,30.3,20.2,31.7c3.1,1.4,21.6,27.5,25.3,33.3c1.6,2.6,2.7,5.5,3.5,8.3C161.7,377.5,165.9,374.8,163.8,371z",
        fill: "#b2b2b2" },
    b14: { key: "b14",
        d: "M223.7,419c-2-3-24.4-30.5-22.5-32.2c1.9-1.6,6,4.1,12.8,6.5c6.8,2.3,11.3-0.3,10.6-2.5c-0.7-2.1-7.7-4.7-9.5-7.5c-1.9-2.8-6.8-4.1-8.9-5.9c-2.9-2.4-5.8-4.1-8.5-5.3c-5.9,1.4-11.9,0.8-17.1-3c-0.7-0.5-1.4-1-2-1.6c-0.3,1.2-0.8,2.1-1.7,2.8c-2.4,2.1-5.2,4-8.1,4.9c8.9,19.6,19.2,38.6,32.2,55.7c-3.1-7.2-7.7-17.8-6.5-18.1c1.6-0.5,14.9,28,21.1,25.2c3.8-1.6,0.9-4.8,0-7.6c-0.9-2.8-14.4-21.7-13.4-23c1.5-1.9,18.8,24.1,23.7,20.4C228.6,425.8,226.1,422.8,223.7,419z",
        fill: "#b2b2b2" },
    b15: { key: "b15",
        d: "M163.2,375.6c-1.4,1.6-3.9,2.9-6.8,4.1c1,3.7,1.6,7.3,2.1,10c0.9,4.7,3.3,8.7,5.2,12.7c1.9,4,7,8.3,19,33.3c2,4.1,6.1,1.7,5.7-0.6c-1.6-8.6-6.3-20.2-3.7-18.2c1.2,1,11.5,25.1,14.7,26.1c4.2,1.3,5.2-2.3,4-6c-0.3-1-1.2-3.2-2.4-5.9c-13-17.1-23.3-36.1-32.2-55.7C166.9,375.8,165,376,163.2,375.6z",
        fill: "#b2b2b2" },
    b16: { key: "b16",
        d: "M80.2,398.6c-1.3-28.1-12.4-47-15.5-54.4c-0.8-1.9-1.1-3.7-1.3-5.5c-3.5,3.3-7.1,6.6-10.6,10c-1.4,1.9-3.1,1.3-4.7,1.1c13,21.2,17.1,46.2,18.9,70.7C71.1,413.2,76.4,406.2,80.2,398.6z",
        fill: "#b2b2b2" },
    b17: { key: "b17",
        d: "M0.5,446.1c1.4,13.4,4.6,28.2,6.6,43.1c1.9,14.6,1,26.6,0.4,40.6c2.4-2.5,4-5.8,5.2-8.9C22.9,496.1,20.2,465.4,0.5,446.1z",
        fill: "#b2b2b2" },
    b18: { key: "b18",
        d: "M60.8,568c0.9-6.8,0-18.5,0.9-27.5c0.9-8.9,10.8-35.2,15-53.7c4.2-18.5,4.7-59.4,3.5-87.8c0-0.2,0-0.3,0-0.5c-3.8,7.6-9.1,14.7-13.1,21.9c0-0.6-0.1-1.2-0.1-1.8c-3.6,15.9-3.6,33.6-8.5,49.5c-9.9,29.7-13.6,60.7-8.7,92.3c1.5,8.7,3.9,17.4,10.3,24C59.8,578.6,60.1,572.8,60.8,568z",
        fill: "#b2b2b2" },
    b19: { key: "b19",
        d: "M-0.2,404.3c0,16.3,0,26.9,0,29.2c0,4,0.3,8.2,0.7,12.5C16,461.3,21,483.6,17.4,504.5c3.7,13.9,3.9,28.9-1.4,41.7c2.4,11.8,3.3,23.9,12.8,32.9c1.9,2.5,5.6,3.7,8.7,1.9c6.2-4.2,10.3-9.7,13.3-16c-0.3-1.5-0.6-3-0.9-4.5c-5-31.6-1.2-62.6,8.7-92.3c4.6-15.1,4.9-31.7,8-47c-0.4-1.7-0.7-3.4-1.2-5C48,400,20.5,391.4-0.2,404.3z",
        fill: "#b2b2b2" },
    b20: { key: "b20",
        d: "M17.4,504.5c-1,5.6-2.6,11.1-4.7,16.3c-1.2,3.1-2.8,6.4-5.2,8.9c-0.2,4.8-0.4,9.9-0.4,15.5c0,5.7,0.2,10.2,0.6,14.1C20.9,544.6,22.4,523.6,17.4,504.5z",
        fill: "#b2b2b2" },
    b21: { key: "b21",
        d: "M58.8,666.2c1.3-5.3,2.6-10.9,3.6-16.7c5.6-30.5-0.5-48.8-1.6-57.3c-0.4-2.6-0.6-5.2-0.7-7.7c-5.3-5.5-7.8-12.4-9.4-19.5c-2.9,6.3-7,11.8-13.3,16c-2.1,1.3-4.5,1.1-6.4,0.1C32.8,611.4,28.8,652.2,58.8,666.2z",
        fill: "#b2b2b2" },
    b22: { key: "b22",
        d: "M21.3,691.8c10.8-35.3,9.6-72.3,9.8-108.8c0-0.7-0.1-1.3-0.1-2c-0.9-0.5-1.7-1.1-2.3-1.9c-9.5-9-10.5-21.1-12.8-32.9c-2,4.7-4.7,9.2-8.2,13.1c1.1,11,3.3,16.7,5.2,27.5c2.6,14.6,0.9,18.5-1.9,33.1s0.5,25.6,1.6,36.8c0.9,8.9,2.9,25.9,3.7,32.6c0.1,1.2-1.1,3.4-2.1,4.1c-1,0.7-2,1.5-2.9,2.3C15.6,694.9,20.8,694.5,21.3,691.8z",
        fill: "#b2b2b2" },
    b23: { key: "b23",
        d: "M45.3,729.9c-0.8-6.3-1-18.7,0.2-22.5c1.1-3.6,7.8-19.3,13.3-41.2c-29.3-13.7-26.1-53.2-27.7-83.3c-0.2,36.6,1,73.5-9.8,108.8c-0.3,1.3-1.5,2-3.3,2.5C34,695.9,40.7,715.1,45.3,729.9z",
        fill: "#b2b2b2" },
    b24: { key: "b24",
        d: "M53,766.1c0,0,0.2-9.4-0.9-14.6s-5.2-14.6-6.3-19c-0.2-0.7-0.3-1.6-0.5-2.6c-4.6-14.9-11.3-34-27.3-35.6c-2,0.6-4.5,0.9-6.7,1.4c-1.9,1.6-3.4,3.3-4.3,5.2c-3.6,7.3-0.7,15.6,2.4,23.6c6.8,0.4,13,5.3,16.8,10.7c8.1,10.5,6.8,24.8,14.2,35.9c3.3,5.4,5.5,10.5,7.6,15.8c0.9,0.1,2.2,0,3.2-0.8c2.7-2.4,1.6-5.8,1.6-5.8s2.7,0.3,3.8-3.4C57.3,774.2,53,766.1,53,766.1z",
        fill: "#b2b2b2" },
    b25: { key: "b25",
        d: "M26.3,735.2c-3.8-5.4-9.9-10.3-16.8-10.7c1.9,5,3.9,9.8,4.6,14.2c1.6,11.5-3.3,17.4-4.5,27.7c-1.2,10.3,3.5,7.5,4.7,19c1.2,11.5,5.6,14.8,10.6,14.8s5.4-5.8,5.4-5.8s2.8,3.4,7,2c3.3-1.1,2.7-4.7,2.6-4.8c0,0,0,0,0,0s0,0,0,0c0.1,0.1,2.3,2.1,5.6,0c2-1.3,1.2-4.9,1.2-4.9s0.6,0.2,1.4,0.3c-2-5.2-4.2-10.4-7.6-15.8C33.1,759.9,34.3,745.7,26.3,735.2z",
        fill: "#b2b2b2" },
};

export const leftBack = {
    b1: { key: "b1",
        d: "M197.9,10.6c11.6-11.9,30-10.6,30-10.6h1.2c0,0,0,22.6,0,57.5c-2,0.4-3.9,0.7-5.5,0.7C199.8,60.4,198.7,31.1,197.9,10.6z",
        fill: "#b2b2b2" },
    b2: { key: "b2",
        d: "M215.5,84.2c-13.9-6.6-16-24.4-24.7-36.2c-0.9-3.8-4.8-23.5,5.6-35.7c0.5-0.6,1-1.1,1.5-1.6c0.7,17.8,1.6,42.4,17.6,46.9C215.6,66.3,216.1,75.5,215.5,84.2z",
        fill: "#b2b2b2" },
    b3: { key: "b3",
        d: "M201.2,90.9c-0.2-1.2-0.4-2.2-0.6-3.1c-1.4-6.1-5.4-12.2-5.4-12.2s0,0-0.7,0.5c-0.7,0.5-1.9-1.9-3.1-4c-1.2-2.1-4.7-11.3-4.9-18.3c-0.2-7,4.5-5.2,4.5-5.2s-0.1-0.2-0.2-0.6c8.7,11.8,10.8,29.6,24.7,36.2C212,88.6,206.7,91.1,201.2,90.9z",
        fill: "#b2b2b2" },
    b4: { key: "b4",
        d: "M197.9,123.5c2.8-3.5,4.3-7.1,4.6-9.2c0.6-3.8-0.2-16.2-1.3-23.5c5.5,0.2,10.8-2.3,14.3-6.7C214.9,98.6,219,125.8,197.9,123.5z",
        fill: "#b2b2b2" },
    b5: { key: "b5",
        d: "M154.2,172.8c0-0.1,0-0.2-0.1-0.2c16.4-7.5,32.3-15.6,43.4-31.3c5.6-7.4,9.3-15.5,14.2-23.5c0.1-0.4,0.3-0.7,0.4-1.1c4.4-8.6,2.9-23.1,3.3-32.3c0.6-8.7,0.1-17.9,0-26.6c2.3,0.7,5,0.9,8,0.6c1.6,0,3.4-0.3,5.5-0.7c0,93.5,0,275.5,0,346.8c-20.7-12.9-48.2-4.3-65.5,11.9c-0.4,1.7-0.8,3.4-1.2,5c-0.2-0.8-0.3-1.7-0.5-2.5c1.9-23.9,6.1-48.2,18.8-68.9c0.5,0,1-0.1,1.5,0.1c4.3-40.9,2.5-82.4-8.1-122.6C169.1,208.1,164.7,190.1,154.2,172.8z",
        fill: "#b2b2b2" },
    b6: { key: "b6",
        d: "M197.6,141.2c-14.9,21.1-38.4,28.5-60.1,39c-5.2,2.6-11.1,3.7-17.2,4.6c0.3-1.8,0.6-3.6,0.8-5.5c1.2-9.9,8.7-23.7,21.1-33.6c12.4-9.9,25.3-11.3,39.4-13.1c7.6-1,12.9-5,16.3-9.1c7.6,0.8,11.9-2.2,14.4-7c-0.2,0.4-0.3,0.7-0.4,1.1C206.9,125.7,203.1,133.8,197.6,141.2z",
        fill: "#b2b2b2" },
    b7: { key: "b7",
        d: "M137.5,180.2c5.5-2.7,11.1-5.1,16.7-7.7c0,0.1,0,0.2,0.1,0.2c10.5,17.3,14.9,35.3,19.8,54.5c10.5,40.3,12.4,81.8,8.1,122.6c-1.9-0.6-4.3,1.2-6.2-1.2c-3.4-3.4-7.1-6.7-10.6-10c0.4-5.5-1.3-10.9-1.3-18c0-9.4,0-13.1,0.9-24.9c0.9-11.7-4.7-20.2-9.2-31s-5.9-39.9-5.9-39.9s-0.2,0.6-0.5,1.6c-1.3-6.3-5.1-12.3-7-19c-0.1,0.2-0.1,0.4-0.2,0.6c-2.6-8.8-4.5-18-7.2-26.8C135.9,181,136.7,180.6,137.5,180.2z",
        fill: "#b2b2b2" },
    b8: { key: "b8",
        d: "M125.1,255.2c5-16.1,13-31,17.3-47.7c2,6.7,5.7,12.7,7,19c-1.3,4.6-5.1,17.6-7.1,21.9c-2.3,5.2-11.5,19.7-14.3,25.1c-0.2,0.3-0.3,0.7-0.5,1.1c-0.8-2.5-1.9-4.9-3.1-7.1C122.6,263.8,123.9,258.9,125.1,255.2z",
        fill: "#b2b2b2" },
    b9: { key: "b9",
        d: "M107.7,248.2c14.4-19.7,20.8-42.5,27.9-65.1c2.4,8.2,4.3,16.7,6.7,25c-4.4,16.5-12.3,31.2-17.2,47.1c-0.8,2.4-1.6,5.3-1.6,8.1C116.7,260,110.4,255.1,107.7,248.2z",
        fill: "#b2b2b2" },
    b10: { key: "b10",
        d: "M97.7,256.2c3.2-8.9,5.1-18.5,6.3-24c1.6-7.5,10.6-26.8,12-30.7c1.1-3.2,3-9.2,4.4-16.6c5.2-0.8,10.2-1.7,14.8-3.5c0.2,0.6,0.4,1.2,0.5,1.8c-7.2,22.8-13.6,45.9-28.4,65.8C104.7,252.2,101.4,254.5,97.7,256.2z",
        fill: "#b2b2b2" },
    b11: { key: "b11",
        d: "M52,370.4c-0.9-0.7-1.4-1.7-1.7-2.8c12.9-10.5,19.4-25.8,26.5-39.9c1.2-2.5,1.9-6.2,2.5-8.7c5.6-13.5,15.4-31.2,9.1-45.4c2-2.8,3.9-5.6,5.4-8.5c1.5-2.7,2.7-5.7,3.8-8.8c3.7-1.7,7-4,9.5-7.3c0.2-0.3,0.4-0.5,0.6-0.8c2.7,6.9,9,11.8,15.8,15c0,0.6,0.1,1.2,0.2,1.9c-0.7,2.7-1.4,5.4-2.9,8c-9.3,14.2-18,27.9-25.4,42.7C86.1,335.1,74.9,352.4,65,371c-1,1.8-0.6,3.3,0.6,4.6C60.7,376.7,55.8,373.7,52,370.4z",
        fill: "#b2b2b2" },
    b12: { key: "b12",
        d: "M79.3,319c-0.6,2.5-1.2,6.2-2.5,8.7c-7.4,14.9-14.2,31-28.5,41.5c-5.1,3.9-11.2,4.5-17.1,3c3.7-1.7,7-2.5,9.1-3.1c4.8-1.4,8-5.4,10.6-9.9c2.6-4.5,10.3-17.8,12.9-23.2c2.6-5.4,8.7-36.1,8.7-36.1c4.3-11.2,10.6-18.9,15.9-26.3C94.7,287.8,84.9,305.4,79.3,319z",
        fill: "#b2b2b2" },
    b13: { key: "b13",
        d: "M65,371c9.9-18.6,21.1-35.9,30.3-55.1c7.4-14.9,16.1-28.5,25.4-42.7c1.5-2.7,2.3-5.4,2.9-8c0.1,0.9,0.4,1.7,0.8,2.5c1.2,2.1,2.3,4.6,3.1,7.1c-2.5,6.3-3.9,22-6.1,31.7c-2.3,10.3-17.1,30.3-20.2,31.7c-3.1,1.4-21.6,27.5-25.3,33.3c-1.6,2.6-2.7,5.5-3.5,8.3C67.1,377.5,62.9,374.8,65,371z",
        fill: "#b2b2b2" },
    b14: { key: "b14",
        d: "M5.1,419c2-3,24.4-30.5,22.5-32.2c-1.9-1.6-6,4.1-12.8,6.5c-6.8,2.3-11.3-0.3-10.6-2.5c0.7-2.1,7.7-4.7,9.5-7.5c1.9-2.8,6.8-4.1,8.9-5.9c2.9-2.4,5.8-4.1,8.5-5.3c5.9,1.4,11.9,0.8,17.1-3c0.7-0.5,1.4-1,2-1.6c0.3,1.2,0.8,2.1,1.7,2.8c2.4,2.1,5.2,4,8.1,4.9C51.3,394.9,41,413.9,27.9,431c3.1-7.2,7.7-17.8,6.5-18.1c-1.6-0.5-14.9,28-21.1,25.2c-3.8-1.6-0.9-4.8,0-7.6c0.9-2.8,14.4-21.7,13.4-23c-1.5-1.9-18.8,24.1-23.7,20.4C0.2,425.8,2.7,422.8,5.1,419z",
        fill: "#b2b2b2" },
    b15: { key: "b15",
        d: "M65.6,375.6c1.4,1.6,3.9,2.9,6.8,4.1c-1,3.7-1.6,7.3-2.1,10c-0.9,4.7-3.3,8.7-5.2,12.7c-1.9,4-7,8.3-19,33.3c-2,4.1-6.1,1.7-5.7-0.6c1.6-8.6,6.3-20.2,3.7-18.2c-1.2,1-11.5,25.1-14.7,26.1c-4.2,1.3-5.2-2.3-4-6c0.3-1,1.2-3.2,2.4-5.9c13-17.1,23.3-36.1,32.2-55.7C61.9,375.8,63.8,376,65.6,375.6z",
        fill: "#b2b2b2" },
    b16: { key: "b16",
        d: "M148.6,398.6c1.3-28.1,12.4-47,15.5-54.4c0.8-1.9,1.1-3.7,1.3-5.5c3.5,3.3,7.1,6.6,10.6,10c1.4,1.9,3.1,1.3,4.7,1.1c-13,21.2-17.1,46.2-18.9,70.7C157.6,413.2,152.4,406.2,148.6,398.6z",
        fill: "#b2b2b2" },
    b17: { key: "b17",
        d: "M228.3,446.1c-1.4,13.4-4.6,28.2-6.6,43.1c-1.9,14.6-1,26.6-0.4,40.6c-2.4-2.5-4-5.8-5.2-8.9C205.9,496.1,208.6,465.4,228.3,446.1z",
        fill: "#b2b2b2" },
    b18: { key: "b18",
        d: "M168,568c-0.9-6.8,0-18.5-0.9-27.5c-0.9-8.9-10.8-35.2-15-53.7c-4.2-18.5-4.7-59.4-3.5-87.8c0-0.2,0-0.3,0-0.5c3.8,7.6,9.1,14.7,13.1,21.9c0-0.6,0.1-1.2,0.1-1.8c3.6,15.9,3.6,33.6,8.5,49.5c9.9,29.7,13.6,60.7,8.7,92.3c-1.5,8.7-3.9,17.4-10.3,24C169,578.6,168.7,572.8,168,568z",
        fill: "#b2b2b2" },
    b19: { key: "b19",
        d: "M229,404.3c0,16.3,0,26.9,0,29.2c0,4-0.3,8.2-0.7,12.5c-15.5,15.2-20.5,37.5-16.9,58.4c-3.7,13.9-3.9,28.9,1.4,41.7c-2.4,11.8-3.3,23.9-12.8,32.9c-1.9,2.5-5.6,3.7-8.7,1.9c-6.2-4.2-10.3-9.7-13.3-16c0.3-1.5,0.6-3,0.9-4.5c5-31.6,1.2-62.6-8.7-92.3c-4.6-15.1-4.9-31.7-8-47c0.4-1.7,0.7-3.4,1.2-5C180.8,400,208.3,391.4,229,404.3z",
        fill: "#b2b2b2" },
    b20: { key: "b20",
        d: "M211.4,504.5c1,5.6,2.6,11.1,4.7,16.3c1.2,3.1,2.8,6.4,5.2,8.9c0.2,4.8,0.4,9.9,0.4,15.5c0,5.7-0.2,10.2-0.6,14.1C207.9,544.6,206.4,523.6,211.4,504.5z",
        fill: "#b2b2b2" },
    b21: { key: "b21",
        d: "M170,666.2c-1.3-5.3-2.6-10.9-3.6-16.7c-5.6-30.5,0.5-48.8,1.6-57.3c0.4-2.6,0.6-5.2,0.7-7.7c5.3-5.5,7.8-12.4,9.4-19.5c2.9,6.3,7,11.8,13.3,16c2.1,1.3,4.5,1.1,6.4,0.1C196,611.4,200,652.2,170,666.2z",
        fill: "#b2b2b2" },
    b22: { key: "b22",
        d: "M207.5,691.8c-10.8-35.3-9.6-72.3-9.8-108.8c0-0.7,0.1-1.3,0.1-2c0.9-0.5,1.7-1.1,2.3-1.9c9.5-9,10.5-21.1,12.8-32.9c2,4.7,4.7,9.2,8.2,13.1c-1.1,11-3.3,16.7-5.2,27.5c-2.6,14.6-0.9,18.5,1.9,33.1c2.8,14.6-0.5,25.6-1.6,36.8c-0.9,8.9-2.9,25.9-3.7,32.6c-0.1,1.2,1.1,3.4,2.1,4.1c1,0.7,2,1.5,2.9,2.3C213.2,694.9,208,694.5,207.5,691.8z",
        fill: "#b2b2b2" },
    b23: { key: "b23",
        d: "M183.5,729.9c0.8-6.3,1-18.7-0.2-22.5c-1.1-3.6-7.8-19.3-13.3-41.2c29.3-13.7,26.1-53.2,27.7-83.3c0.2,36.6-1,73.5,9.8,108.8c0.3,1.3,1.5,2,3.3,2.5C194.8,695.9,188.1,715.1,183.5,729.9z",
        fill: "#b2b2b2" },
    b24: { key: "b24",
        d: "M175.8,766.1c0,0-0.2-9.4,0.9-14.6c1.2-5.2,5.2-14.6,6.3-19c0.2-0.7,0.3-1.6,0.5-2.6c4.6-14.9,11.3-34,27.3-35.6c2,0.6,4.5,0.9,6.7,1.4c1.9,1.6,3.4,3.3,4.3,5.2c3.6,7.3,0.7,15.6-2.4,23.6c-6.8,0.4-13,5.3-16.8,10.7c-8.1,10.5-6.8,24.8-14.2,35.9c-3.3,5.4-5.5,10.5-7.6,15.8c-0.9,0.1-2.2,0-3.2-0.8c-2.7-2.4-1.6-5.8-1.6-5.8s-2.7,0.3-3.8-3.4C171.5,774.2,175.8,766.1,175.8,766.1z",
        fill: "#b2b2b2" },
    b25: { key: "b25",
        d: "M202.5,735.2c3.8-5.4,9.9-10.3,16.8-10.7c-1.9,5-3.9,9.8-4.6,14.2c-1.6,11.5,3.3,17.4,4.5,27.7c1.2,10.3-3.5,7.5-4.7,19c-1.2,11.5-5.6,14.8-10.6,14.8c-4.9,0-5.4-5.8-5.4-5.8s-2.8,3.4-7,2c-3.3-1.1-2.7-4.7-2.6-4.8c0,0,0,0,0,0s0,0,0,0c-0.1,0.1-2.3,2.1-5.6,0c-2-1.3-1.2-4.9-1.2-4.9s-0.6,0.2-1.4,0.3c2-5.2,4.2-10.4,7.6-15.8C195.7,759.9,194.5,745.7,202.5,735.2z",
        fill: "#b2b2b2" },
};