
const FieldsModel = {
    //vitals
    vitals_height: 'Ръст',
    vitals_weight: 'Тегло',
    vitals_bloodPressure: 'Кръвно налягане',
    vitals_pulse: 'Пулс',
    vitals_saturation: 'Сатурация',
    vitals_medHistory: 'Анамнеза',

    //blood

    //caliper
    caliper_subscapula: 'Подскапула',
    caliper_biceps: 'Бицепс',
    caliper_triceps: 'Трицепс',
    caliper_nadialic: 'Надиалична',

    //body scales
    bodyScales_fat: 'Мазнини',
    bodyScales_hydration: 'Хидратация',
    bodyScales_muscles: 'Мускули',
    bodyScales_bones: 'Кости',
    bodyScales_basalMetabolism: 'Базален метаболизъм',
    bodyScales_activeMetabolism: 'Активен метаболизъм',

    //feet
    feet_image: 'Снимка (стъпала)',
    feet_recommendations: 'Препоръки (стъпала)',
    feet_supPro_left: 'Ляво ходило (картинка)',
    feet_supPro_right: 'Дясно ходило (картинка)',
    feet_left: 'Ляво ходило (чекбокс)',
    feet_right: 'Дясно ходило (чекбокс)',
    feet_arch_left: 'Ляв свод',
    feet_arch_right: 'Десен свод',

    //spine
    spine_neck_flexion: 'Шиен дял флексия',
    spine_neck_extension: 'Шиен дял екстензия',
    spine_neck_lateral_left: 'Шиен дял латерални наклони ляво',
    spine_neck_lateral_right: 'Шиен дял латерални наклони дясно',
    spine_neck_rotation_left: 'Шиен дял ротация ляво',
    spine_neck_rotation_right: 'Шиен дял ротация дясно',
    spine_curvature: 'Гръбначен стълб (картинка)',
    spine_cervical: 'Шиен дял (общ преглед)',
    spine_grip_left: 'Сила на захват ляво',
    spine_grip_right: 'Сила на захват дясно',
    spine_handedness: 'Ръкост',
    spine_thoracic_curv: 'Гръден дял деформация',
    spine_thoracic_degrees: 'Гръден дял градуси',
    spine_thoracic_direction: 'Гръден дял посока',
    spine_collarBone_left: 'Ключица ляво',
    spine_collarBone_right: 'Ключица дясно',
    spine_shoulders_left: 'Рамене ляво',
    spine_shoulders_right: 'Рамене дясно',
    spine_lumbar_lord: 'Лумбален дял (общ преглед)',
    spine_lumbar_curv: 'Лумбален дял деформация',
    spine_lumbar_degrees: 'Лумбален дял градуси',
    spine_lumbar_direction: 'Лумбален дял посока',
    spine_lateral_left: 'Лумбален дял латерални наклони ляво',
    spine_lateral_right: 'Лумбален дял латерални наклони дясно',
    spine_c7s1: 'Измерване на От(C7-S1)',
    spine_th12: 'Измерване на От(Th12-S1)',
    spine_c7th12: 'Измерване на От(C7-Th12)',
    spine_scoliometerNote: 'Бележка (сколиометър)',

    //goniometry
    goniometry_hip_left_extension: 'Лява ТБС екстензия',
    goniometry_hip_left_flexion: 'Лява ТБС флексия',
    goniometry_hip_left_flexionStrKnee: 'Лява ТБС флексия с изпънато коляно',
    goniometry_hip_left_abduction: 'Лява ТБС абдукция',
    goniometry_hip_left_adduction: 'Лява ТБС аддукция',
    goniometry_hip_left_rotationInner: 'Лява ТБС вътрешна ротация',
    goniometry_hip_left_rotationOuter: 'Лява ТБС външна ротация',
    goniometry_hip_right_extension: 'Дясна ТБС екстензия',
    goniometry_hip_right_flexion: 'Дясна ТБС флексия',
    goniometry_hip_right_flexionStrKnee: 'Дясна ТБС флексия с изпънато коляно',
    goniometry_hip_right_abduction: 'Дясна ТБС абдукция',
    goniometry_hip_right_adduction: 'Дясна ТБС аддукция',
    goniometry_hip_right_rotationInner: 'Дясна ТБС вътрешна ротация',
    goniometry_hip_right_rotationOuter: 'Дясна ТБС външна ротация',

    goniometry_shoulder_left_extension: 'Лява раменна става екстензия',
    goniometry_shoulder_left_flexion: 'Лява раменна става флексия',
    goniometry_shoulder_left_abduction: 'Лява раменна става абдукция',
    goniometry_shoulder_left_adduction: 'Лява раменна става аддукция',
    goniometry_shoulder_left_rotationInner: 'Лява раменна става вътрешна ротация',
    goniometry_shoulder_left_rotationOuter: 'Лява раменна става външна ротация',
    goniometry_shoulder_right_extension: 'Дясна раменна става екстензия',
    goniometry_shoulder_right_flexion: 'Дясна раменна става флексия',
    goniometry_shoulder_right_abduction: 'Дясна раменна става абдукция',
    goniometry_shoulder_rightt_adduction: 'Дясна раменна става аддукция',
    goniometry_shoulder_right_rotationInner: 'Дясна раменна става вътрешна ротация',
    goniometry_shoulder_right_rotationOuter: 'Дясна раменна става външна ротация',

    goniometry_ankle_left_extension: 'Лява глезенна става екстензия',
    goniometry_ankle_left_flexion: 'Лява глезенна става флексия',
    goniometry_ankle_right_extension: 'Дясна глезенна става екстензия',
    goniometry_ankle_right_flexion: 'Дясна глезенна става флексия',

    goniometry_knee_left_extension: 'Лява колянна става екстензия',
    goniometry_knee_left_flexion: 'Лява колянна става флексия',
    goniometry_knee_right_extension: 'Дясна колянна става екстензия',
    goniometry_knee_right_flexion: 'Дясна колянна става флексия',

    goniometry_elbow_left_extension: 'Лява лакътна става екстензия',
    goniometry_elbow_left_flexion: 'Лява лакътна става флексия',
    goniometry_elbow_left_supination: 'Лява лакътна става супинация',
    goniometry_elbow_left_pronation: 'Лява лакътна става пронация',
    goniometry_elbow_right_extension: 'Дясна лакътна става екстензия',
    goniometry_elbow_right_flexion: 'Дясна лакътна става флексия',
    goniometry_elbow_right_supination: 'Дясна лакътна става супинация',
    goniometry_elbow_right_pronation: 'Дясна лакътна става пронация',

    goniometry_wrist_left_extension: 'Лява става на китката екстензия',
    goniometry_wrist_left_flexion: 'Лява става на китката флексия',
    goniometry_wrist_left_radial: 'Лява става на китката радиално отвеждане',
    goniometry_wrist_left_ulnar: 'Лява става на китката улнарно отвеждане',
    goniometry_wrist_right_extension: 'Дясна става на китката екстензия',
    goniometry_wrist_right_flexion: 'Дясна става на китката флексия',
    goniometry_wrist_right_radial: 'Дясна става на китката радиално отвеждане',
    goniometry_wrist_right_ulnar: 'Дясна става на китката улнарно отвеждане',

    //elasticity
    elasticity_abdomen: 'Коремни мускули',

    //centimetrics
    centimetrics_hip_left: 'Сантиметрия ляво бедро',
    centimetrics_hip_right: 'Сантиметрия дясно бедро',
    centimetrics_shank_left: 'Сантиметрия лява подбедрица',
    centimetrics_shank_right: 'Сантиметрия дясна подбедрица',
    centimetrics_shoulders: 'Сантиметрия раменен пояс',
    centimetrics_neck: 'Сантиметрия врат',
    centimetrics_abdomen: 'Сантиметрия корем',
    centimetrics_chest: 'Сантиметрия гърди',
    centimetrics_biceps_left: 'Сантиметрия ляв бицепс',
    centimetrics_biceps_right: 'Сантиметрия десен бицепс',
    centimetrics_forearm_left: 'Сантиметрия лява предмишница',
    centimetrics_forearm_right: 'Сантиметрия дясна предмишница',

    //boneLength
    centimetrics_femur_left: 'Бедрена кост ляв крак',
    centimetrics_femur_right: 'Бедрена кост десен крак',
    centimetrics_tibia_left: 'Голям и малък пищял ляв крак',
    centimetrics_tibia_right: 'Голям и малък пищял десен крак',
    centimetrics_humerus_left: 'Раменна кост лява ръка',
    centimetrics_humerus_right: 'Раменна кост дясна ръка',
    centimetrics_radius_left: 'Лакътна и лъчева кост лява ръка',
    centimetrics_radius_right: 'Лакътна и лъчева кост дясна ръка',

    //forcemetry
    forcemetry_backExt: 'Силометрия екстензори на гърба',
    forcemetry_trapezius_left: 'Силометрия трапец ляво',
    forcemetry_trapezius_right: 'Силометрия трапец дясно',
    forcemetry_neckExt: 'Силометрия екстензори на врата',
    forcemetry_neckFlex: 'Силометрия флексори на врата',
    forcemetry_neckLateral_left: 'Силометрия латерални наклони на врата ляво',
    forcemetry_neckLateral_right: 'Силометрия латерални наклони на врата дясно',
    forcemetry_teresMajor_left: 'Силометрия Teres major ляво',
    forcemetry_teresMajor_right: 'Силометрия Teres major дясно',
    forcemetry_teresMinor_left: 'Силометрия Teres minor ляво',
    forcemetry_teresMinor_right: 'Силометрия Teres minor дясно',
    forcemetry_biceps_left: 'Силометрия бицепс ляво',
    forcemetry_biceps_right: 'Силометрия бицепс дясно',
    forcemetry_triceps_left: 'Силометрия трицепс ляво',
    forcemetry_triceps_right: 'Силометрия трицепс дясно',
    forcemetry_wristExt_left: 'Силометрия екстензори на китката ляво',
    forcemetry_wristExt_right: 'Силометрия екстензори на китката дясно',
    forcemetry_wristFlex_left: 'Силометрия флексори на китката ляво',
    forcemetry_wristFlex_right: 'Силометрия флексори на китката дясно',
    forcemetry_latDorsi_left: 'Силометрия Латисмус Дорзи ляво',
    forcemetry_latDorsi_right: 'Силометрия Латисмус Дорзи дясно',
    forcemetry_shoulderFront_left: 'Силометрия предно рамо ляво',
    forcemetry_shoulderFront_right: 'Силометрия предно рамо дясно',
    forcemetry_shoulderMid_left: 'Силометрия средно рамо ляво',
    forcemetry_shoulderMid_right: 'Силометрия средно рамо дясно',
    forcemetry_shoulderBack_left: 'Силометрия задно рамо ляво',
    forcemetry_shoulderBack_right: 'Силометрия задно рамо дясно',
    forcemetry_chest_left: 'Силометрия гръдни мускули ляво',
    forcemetry_chest_right: 'Силометрия гръдни мускули дясно',
    forcemetry_abdomen: 'Силометрия коремни мускули',
    forcemetry_waistLat_left: 'Силометрия латерален наклон на кръста ляво',
    forcemetry_waistLat_right: 'Силометрия латерален наклон на кръста дясно',
    forcemetry_gluteus_left: 'Силометрия глутеус ляво',
    forcemetry_gluteus_right: 'Силометрия глутеус дясно',
    forcemetry_backHip_left: 'Силометрия задно бедро ляво',
    forcemetry_backHip_right: 'Силометрия задно бедро дясно',
    forcemetry_shank_left: 'Силометрия подбедрица ляво',
    forcemetry_shank_right: 'Силометрия подбедрица дясно',
    forcemetry_hip_left: 'Силометрия предно бедро ляво',
    forcemetry_hip_right: 'Силометрия предно бедро дясно',
    forcemetry_tibialis_left: 'Силометрия тибиалис ляво',
    forcemetry_tibialis_right: 'Силометрия тибиалис дясно',
    forcemetry_abductors_left: 'Силометрия абдуктори ляво',
    forcemetry_abductors_right: 'Силометрия абдуктори дясно',
    forcemetry_adductors_left: 'Силометрия аддуктори ляво',
    forcemetry_adductors_right: 'Силометрия аддуктори дясно',

    //thermography
    thermography_spine_note: 'Термография гръбн. стълб бележка',
    thermography_spine_degreesMin: 'Термография гръбн. стълб градуси мин.',
    thermography_spine_degreesMax: 'Термография гръбн. стълб градуси макс.',
    thermography_spine_image: 'Термография гръбн. стълб снимка',
    thermography_neck_note: 'Термография шиен дял бележка',
    thermography_neck_degreesMin: 'Термография шиен дял градуси мин.',
    thermography_neck_degreesMax: 'Термография шиен дял градуси макс.',
    thermography_neck_image: 'Термография шиен дял снимка',
    thermography_thoracic_note: 'Термография гръден дял бележка',
    thermography_thoracic_degreesMin: 'Термография гръден дял градуси мин.',
    thermography_thoracic_degreesMax: 'Термография гръден дял градуси макс.',
    thermography_thoracic_image: 'Термография гръден дял снимка',
    thermography_lumbar_note: 'Термография лумбален дял бележка',
    thermography_lumbar_degreesMin: 'Термография лумбален дял градуси мин.',
    thermography_lumbar_degreesMax: 'Термография лумбален дял градуси макс.',
    thermography_lumbar_image: 'Термография лумбален дял снимка',
    thermography_sacral_note: 'Термография сакрален дял бележка',
    thermography_sacral_degreesMin: 'Термография сакрален дял градуси мин.',
    thermography_sacral_degreesMax: 'Термография сакрален дял градуси макс.',
    thermography_sacral_image: 'Термография сакрален дял снимка',
    thermography_rArmLat_note: 'Термография д. г. крайник (лат.) бележка',
    thermography_rArmLat_degreesMin: 'Термография д. г. крайник (лат.) градуси мин.',
    thermography_rArmLat_degreesMax: 'Термография д. г. крайник (лат.) градуси макс.',
    thermography_rArmLat_image: 'Термография д. г. крайник (лат.) снимка',
    thermography_rArmMed_note: 'Термография д. г. крайник (мед.) бележка',
    thermography_rArmMed_degreesMin: 'Термография д. г. крайник (мед.) градуси мин.',
    thermography_rArmMed_degreesMax: 'Термография д. г. крайник (мед.) градуси макс.',
    thermography_rArmMed_image: 'Термография д. г. крайник (мед.) снимка',
    thermography_lArmLat_note: 'Термография л. г. крайник (лат.) бележка',
    thermography_lArmLat_degreesMin: 'Термография л. г. крайник (лат.) градуси мин.',
    thermography_lArmLat_degreesMax: 'Термография л. г. крайник (лат.) градуси макс.',
    thermography_lArmLat_image: 'Термография л. г. крайник (лат.) снимка',
    hermography_lArmMed_note: 'Термография л. г. крайник (мед.) бележка',
    thermography_lArmMed_degreesMin: 'Термография л. г. крайник (мед.) градуси мин.',
    thermography_lArmMed_degreesMax: 'Термография л. г. крайник (мед.) градуси макс.',
    thermography_lArmMed_image: 'Термография л. г. крайник (мед.) снимка',
    thermography_elbowLat_note: 'Термография лакътна става (лат.) бележка',
    thermography_elbowLat_degreesMin: 'Термография лакътна става (лат.) градуси мин.',
    thermography_elbowLat_degreesMax: 'Термография лакътна става (лат.) градуси макс.',
    thermography_elbowLat_image: 'Термография лакътна става (лат.) снимка',
    thermography_elbowMed_note: 'Термография лакътна става (мед.) бележка',
    thermography_elbowMed_degreesMin: 'Термография лакътна става (мед.) градуси мин.',
    thermography_elbowMed_degreesMax: 'Термография лакътна става (мед.) градуси макс.',
    thermography_elbowMed_image: 'Термография лакътна става (мед.) снимка',
    thermography_wristSup_note: 'Термография киткена става (суп.) бележка',
    thermography_wristSup_degreesMin: 'Термография киткена става (суп.) градуси мин.',
    thermography_wristSup_degreesMax: 'Термография киткена става (суп.) градуси макс.',
    thermography_wristSup_image: 'Термография киткена става (суп.) снимка',
    thermography_wristPro_note: 'Термография киткена става (пр.) бележка',
    thermography_wristPro_degreesMin: 'Термография киткена става (пр.) градуси мин.',
    thermography_wristPro_degreesMax: 'Термография киткена става (пр.) градуси макс.',
    thermography_wristPro_image: 'Термография киткена става (пр.) снимка',
    thermography_shoulderFront_note: 'Термография раменна става (предно) бележка',
    thermography_shoulderFront_degreesMin: 'Термография раменна става (предно) градуси мин.',
    thermography_shoulderFront_degreesMax: 'Термография раменна става (предно) градуси макс.',
    thermography_shoulderFront_image: 'Термография раменна става (предно) снимка',
    thermography_shoulderBack_note: 'Термография раменна става (задно) бележка',
    thermography_shoulderBack_degreesMin: 'Термография раменна става (задно) градуси мин.',
    thermography_shoulderBack_degreesMax: 'Термография раменна става (задно) градуси макс.',
    thermography_shoulderBack_image: 'Термография раменна става (задно) снимка',
    thermography_rLegFront_note: 'Термография д. д. крайник (отпред) бележка',
    thermography_rLegFront_degreesMin: 'Термография д. д. крайник (отпред) градуси мин.',
    thermography_rLegFront_degreesMax: 'Термография д. д. крайник (отпред) градуси макс.',
    thermography_rLegFront_image: 'Термография д. д. крайник (отпред) снимка',
    thermography_lLegFront_note: 'Термография л. д. крайник (отпред) бележка',
    thermography_lLegFront_degreesMin: 'Термография л. д. крайник (отпред) градуси мин.',
    thermography_lLegFront_degreesMax: 'Термография л. д. крайник (отпред) градуси макс.',
    thermography_lLegFront_image: 'Термография л. д. крайник (отпред) снимка',
    thermography_kneeFront_note: 'Термография колянна става (отпред) бележка',
    thermography_kneeFront_degreesMin: 'Термография колянна става (отпред) градуси мин.',
    thermography_kneeFront_degreesMax: 'Термография колянна става (отпред) градуси макс.',
    thermography_kneeFront_image: 'Термография колянна става (отпред) снимка',
    thermography_hip_note: 'Термография ТБС бележка',
    thermography_hip_degreesMin: 'Термография ТБС градуси мин.',
    thermography_hip_degreesMax: 'Термография ТБС градуси макс.',
    thermography_hip_image: 'Термография ТБС снимка',
    thermography_ankle_note: 'Термография глезенна става бележка',
    thermography_ankle_degreesMin: 'Термография глезенна става градуси мин.',
    thermography_ankle_degreesMax: 'Термография глезенна става градуси макс.',
    thermography_ankle_image: 'Термография глезенна става снимка',
    thermography_rLegBack_note: 'Термография д. д. крайник (отзад) бележка',
    thermography_rLegBack_degreesMin: 'Термография д. д. крайник (отзад) градуси мин.',
    thermography_rLegBack_degreesMax: 'Термография д. д. крайник (отзад) градуси макс.',
    thermography_rLegBack_image: 'Термография д. д. крайник (отзад) снимка',
    thermography_lLegBack_note: 'Термография л. д. крайник (отзад) бележка',
    thermography_lLegBack_degreesMin: 'Термография л. д. крайник (отзад) градуси мин.',
    thermography_lLegBack_degreesMax: 'Термография л. д. крайник (отзад) градуси макс.',
    thermography_lLegBack_image: 'Термография л. д. крайник (отзад) снимка',
    thermography_kneeBack_note: 'Термография колянна става (отзад) бележка',
    thermography_kneeBack_degreesMin: 'Термография колянна става (отзад) градуси мин.',
    thermography_kneeBack_degreesMax: 'Термография колянна става (отзад) градуси макс.',
    thermography_kneeBack_image: 'Термография колянна става (отзад) снимка',
    thermography_achilles_note: 'Термография ахилесово сухожилие бележка',
    thermography_achilles_degreesMin: 'Термография ахилесово сухожилие градуси мин.',
    thermography_achilles_degreesMax: 'Термография ахилесово сухожилие градуси макс.',
    thermography_achilles_image: 'Термография ахилесово сухожилие снимка',
};

export default FieldsModel;