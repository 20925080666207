import classes from './SearchBox.module.css';

const SearchBox = (props) => {
    return (
        <div className={classes.filter}>
            {props.children}
        </div>
    );
};

export default SearchBox;