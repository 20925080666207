import { useState } from 'react';
import { useLoaderData, Link, useNavigation, useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Api } from '../../utils/api';
import { convertBase64 } from '../../utils/utils';

const CompanyForm = () => {
    const data = useLoaderData();
    const company = data.company;
    const meta = data.meta;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const isSubmitting = navigation.status === 'sumbitting';

    const { register, getValues, formState: { errors }, handleSubmit, setValue } = useForm({
        defaultValues: company});

    const [uploadedLogo, setUploadedLogo] = useState(getValues('logo'));

    const [serverErrors, setServerErrors] = useOutletContext(data.errors);

    const handleFileRead = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setUploadedLogo(base64);
        setValue('logo', base64);
    }

    const onSubmit = (companyData) => {
        companyData.logo = uploadedLogo;
        Api.setCompanies(companyData).then((responseCompany) => {
            navigate('/companies/' + String(responseCompany.id));
            setServerErrors([]);
        },
        (errors) => {
            setServerErrors(errors.errors);
          });
    }

    return (
        <section className='lists'>
            <h1>{meta.title}</h1>
            <form className='form' onSubmit={handleSubmit(onSubmit)} noValidate>
                <p className='control'>
                    <input  {...register("id")}
                        id="id"
                        type="hidden"
                    />
                </p>
                <p className='control'>
                    <label>Фирма:</label>
                    <input {...register("name", { required: true })}
                        className={errors.name ? "error-input" : ""}
                        id="name"
                        type="text"
                    />
                </p>
                {errors.name && <p className='error-message'>Моля, въведете име на фирма.</p>}
                <p className='control'>
                    <label>Описание:</label>
                    <textarea {...register("description")}
                        id="description"
                        rows='4'
                    />
                </p>
                <p className='control'>
                    <label>Лого:</label>
                    <input
                        {...register("logo")}
                        name="logo"
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={e => handleFileRead(e)}
                    />
                </p>
                <img src={uploadedLogo} style={{maxWidth: '300px', maxHeight: '300px', margin: '1em 0'}}/>
                <div className='action'>
                    <button className="cancel" type='button' onClick={() => navigate('/companies/' + String(company.id))}>Отказ</button>
                    <button disabled={isSubmitting}>
                        {meta.submitButtonTitle}
                    </button>
                </div>
            </form>
        </section>
    );
}

export default CompanyForm;