import { getUserRoleName, userRoles } from '../../utils/api';
import { convertToProperDate } from '../../utils/utils';

import classes from './PersonalCard.module.css';

const UserCard = (props) => {

    return (
        <>
        <div className={classes.dates}>{props.data.dateAdded && <span>Добавен на: {convertToProperDate(props.data.dateAdded)} г.</span>} {props.data.lastModified && <span>Последно обновен на: {convertToProperDate(props.data.lastModified)} г.</span>}</div>
        <div className={classes['card-wrapper']}>
            <div className={classes['data-piece']}>
                <span className={classes.label}>Име:</span>
                <span>{props.data.name} {props.data.family}</span>
            </div>
            <div className={classes['data-piece']}>
                <span className={classes.label}>Роля:</span>
                <span>{getUserRoleName(props.data.role)}</span>
            </div>
            <div className={classes['data-piece']}>
                <span className={classes.label}>E-mail:</span>
                <span>{props.data.email}</span>
            </div>
            {props.role === userRoles.superAdmin && 
            <div className={classes['data-piece']}>
                <span className={classes.label}>Фирма:</span>
                <span>{props.data.company.name}</span>
            </div>
            }
        </div>
        </>
    );
};

export default UserCard;