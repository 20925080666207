
const CurvModel = {
    scoliosis: '1',
    kyphosis: '2',
    getCurvString: (curv) => {
        switch (curv) {
            case CurvModel.scoliosis: 
                return 'сколиоза';
            case CurvModel.kyphosis: 
                return 'кифоза';
        }
    }
};

export default CurvModel;