import ProfileForm from './ProfileForm';

const UserProfile = () => {
  return (
    <section>
      <h1>Твоят профил</h1>
      <ProfileForm />
    </section>
  );
};

export default UserProfile;