import { useState, useMemo } from "react";
import { Link, useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import { useDebounce } from "use-debounce";
import ListWrapper from "../../components/UI/ListWrapper";
import Modal from "../../components/UI/Modal";
import SearchBox from "../../components/UI/SearchBox";
import {Api, userRoles} from '../../utils/api';
import Pagination from "../../components/UI/Pagination";

import classes from '../../components/UI/ListWrapper.module.css';
import {checkUser} from "../../store/auth-context";

let pageSize = process.env.REACT_APP_PAGESIZE;

const PatientsList = () => {
    const [patientsData, setPatientsData] = useState(useLoaderData());
    const [modalIsShown, setModalIsShown] = useState(false);
    const [elemKey, setElemKey] = useState('');
    const [searchName, setSearchName] = useState('');
    const [debouncedText] = useDebounce(searchName, 800);

    const checkedRole = checkUser([userRoles.superAdmin, userRoles.admin, userRoles.employee]);

    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();

    const [serverErrors, setServerErrors] = useOutletContext(patientsData.errors);

    const patientsDataFunc = async () => {
        const filter = {};

        if (debouncedText && debouncedText !== '') {
            filter.name = debouncedText;
        }

        Api.getPatients(filter, currentPage, pageSize).then(
            (patientsData) => {
                setPatientsData({patients: patientsData, errors: []});
                setServerErrors([]);
            },
            (errors) => {
                setServerErrors(errors.errors);
              }
        );
    }

    useMemo(async () => {
        patientsDataFunc();
      }, [currentPage, debouncedText]);

    const deletePatientHandler = (id) => {
        Api.deletePatient(id).then((result) => {
            if (result === true) {
                patientsDataFunc();
                setServerErrors([]);
            }
        }, (errors) => {
            setServerErrors(errors.errors);
          });
    }

    const searchBoxHandler = async (event) => {
        setSearchName(event.target.value);
        setCurrentPage(1);
        patientsDataFunc();
    };

    const clearFilterHandler = async () => {
        setSearchName('');
        setCurrentPage(1);
        patientsDataFunc();
    };

    return (
        <>
        {modalIsShown && 
        <Modal message={'Сигурни ли сте?'} onCancel={() => {setModalIsShown(false);}} onConfirm={() => {deletePatientHandler(elemKey); setModalIsShown(false);}}></Modal>}
        <section className='lists'>
            <h1>Пациенти</h1>
            <SearchBox>
             <div className='filter-wrapper'>
                 <p className='control'>
                    <label htmlFor=''>Филтрирай по име:</label>
                    <input type='search' name={searchName} value={searchName} onChange={searchBoxHandler} />
                </p>
                <div className="action"><button className="cancel" onClick={clearFilterHandler}>Изчисти</button></div>
             </div>
            </SearchBox>
            <div className="action"><button onClick={() => {
                                    navigate('/patients/new');
                                }}>Добави нов</button></div>
            <ListWrapper>
                {patientsData.patients.total === 0 ? 
                    <p>Няма пациенти.</p> :
                    <table>
                        <thead>
                            <tr>
                                <th>Име</th>
                                <th>Възраст</th>
                                <th>Професия</th>
                                <th className={classes.actions}>
                                    {checkedRole !== userRoles.employee && 'Редактирай'}{checkedRole !== userRoles.employee && checkedRole !== userRoles.admin && ' | Изтрий'}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {patientsData.patients.patients.map((patient) => (
                            <tr key={patient.id}>
                                <td><Link to={String(patient.id)}>{patient.name} {patient.family}</Link></td>
                                <td>{patient.age} г.</td>
                                <td>{patient.jobTitle}</td>
                                <td className={classes.actions}>
                                    {checkedRole !== 'ROLE_USER' &&
                                    <button className={classes.edit} onClick={() => {
                                        navigate(patient.id + '/edit');
                                    }}></button>
                                    }
                                    {checkedRole !== 'ROLE_USER' && checkedRole !== 'ROLE_ADMIN' &&
                                    <button className={classes.delete} onClick={() => {
                                        setModalIsShown(true);
                                        setElemKey(patient.id);
                                    }}></button>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
            </ListWrapper>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={patientsData.patients.total}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </section>
        </>
    );
  }
  
  export default PatientsList;