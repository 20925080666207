import { useContext, useEffect } from "react";
import AuthContext, { logOut } from "../../store/auth-context";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);

    authCtx.setToken(''); 
    logOut();

    useEffect(() => {
        navigate('/logout');
    }, []);

    return (
        <></>
    );
  };
  
export default Logout;