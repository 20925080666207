import { useState } from "react";
import { Link, useRouteLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import Modal from "../../components/UI/Modal";
import { Api } from "../../utils/api";

const CompanyDetails = () => {
    const data = useRouteLoaderData('company-details');
    const company = data.company;

    const [modalIsShown, setModalIsShown] = useState(false);

    const navigate = useNavigate();

    const [serverErrors, setServerErrors] = useOutletContext(data.errors);

    const deleteCompanyHandler = (id) => {
        Api.deleteCompany(id).then(async (result) => {
            if (result === true) {
                navigate('/companies');
                setServerErrors([]);
            }
        },
        (errors) => {
            setServerErrors(errors.errors);
        });
    }

    return (
        <>
        {modalIsShown && 
        <Modal message={'Сигурни ли сте?'} onCancel={() => {setModalIsShown(false);}} onConfirm={() => {deleteCompanyHandler(company.id); setModalIsShown(false);}}></Modal>}
        <section className='lists'>
            <h1>{company.name}<span className="action in-title">
                <button className="edit"
                    onClick={() => {
                            navigate('edit');
                        }}>
                </button>
                <button className="delete" 
                    onClick={() => {
                            setModalIsShown(true);
                        }}>
                </button>
            </span></h1>
            {company.logo && <img src={company.logo} alt="logo" className='feet-img' />}
            <p className="important-info-wrapper">{company.description}</p>
        </section>
        <div className="back">
            <Link to=".." relative="path">
                <button>← Към фирмите</button>
            </Link>
        </div>
        </>
    );
}

export default CompanyDetails;