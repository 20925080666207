const InputLR = (props) => {

    return (
        <>
            <p className='control'>
                <label>{props.title}</label>
            </p>
            <div className='control multi-inp'>
                <p className='control short'>
                    <label htmlFor={props.left}>Ляв:</label>
                    <input
                        {...props.register(props.left, {valueAsNumber: true})}
                        type={props.type}
                        step="any"
                    />
                </p>
                <p className='control short'>
                    <label htmlFor={props.right}>Дeсен:</label>
                    <input
                        {...props.register(props.right, {valueAsNumber: true})}
                        type={props.type}
                        step="any"
                    />
                </p>
            </div>
        </>
    );
};

export default InputLR;