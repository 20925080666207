import { useState, useMemo } from "react";
import {Link, useNavigate, useOutletContext, useRouteLoaderData} from "react-router-dom";
import {Api, userRoles} from '../../utils/api';
import ListWrapper from "./ListWrapper";
import Modal from "./Modal";
import Pagination from "./Pagination";
import DatePicker from 'react-date-picker';
import { convertToProperDate } from "../../utils/utils";
import LoadingSpinner from '../../components/UI/LoadingSpinner';
// import SearchContext, { SearchContextProvider } from "../../store/search-context";

import classes from '../../components/UI/ListWrapper.module.css';
import {checkUser} from "../../store/auth-context";

let pageSize = process.env.REACT_APP_PAGESIZE;

const DiagnosticsWrapper = (props) => {
    //const searchCtx = useContext(SearchContext);
    const checkedRole = checkUser([userRoles.superAdmin, userRoles.admin, userRoles.employee]);

    const [diagnosticsData, setDiagnosticsData] = useState(props.data);
    const [modalIsShown, setModalIsShown] = useState(false);
    const [elemKey, setElemKey] = useState('');
    const [searchDate, onDateChange] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();

    const [serverErrors, setServerErrors] = useOutletContext(props.errors);
    
    const diagnosticsDataFunc = async () => {
        const filter = {};

        filter.patientId = props.patientId;
        filter.date = searchDate;

        setIsLoading(true);

        Api.getDiagnostics(filter, currentPage, pageSize).then(
            (diagnosticsData) => {
                setDiagnosticsData(diagnosticsData);
                setIsLoading(false);
                setServerErrors([]);
            },
            (errors) => {
                setServerErrors(errors.errors);
              }
        );
    }

    useMemo(async () => {
        diagnosticsDataFunc();
      }, [currentPage, searchDate]);

    const deleteDiagnosticHandler = (id) => {
        Api.deleteDiagnostic(id).then(async (result) => {
            if (result === true) {
                setDiagnosticsData(await Api.getDiagnostics({patientId: props.patientId, date: searchDate}, currentPage, pageSize));
            }
        });
    }

    return (
        <>
        {modalIsShown && 
        <Modal message={'Сигурни ли сте?'} onCancel={() => {setModalIsShown(false);}} onConfirm={() => {deleteDiagnosticHandler(elemKey); setModalIsShown(false);}}></Modal>}
        <section className='lists'>
            <div className="form">
                <label className="lbl-datepicker">Диагностики от дата:</label>
                <DatePicker onChange={onDateChange} locale="bg-BG" format="dd.MM.y" value={searchDate} />
            </div>
            {isLoading && props.patientId && <LoadingSpinner />}
            {isLoading && !props.patientId && <LoadingSpinner />}
            <ListWrapper>
                    {typeof diagnosticsData !== "undefined" ? diagnosticsData.total === 0 ? 
                        <p>Няма въведени диагностики.</p> :
                    <table>
                        <thead>
                            <tr>
                                <th>Дата</th>
                                {!props.patientId && <th>Пациент</th>}
                                <th className={classes.actions}>
                                {checkedRole !== 'ROLE_USER' && 'Редактирай'}{checkedRole !== 'ROLE_USER' && checkedRole !== 'ROLE_ADMIN' && ' | Изтрий'}
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                    {diagnosticsData.diagnostics.map((diagnostic) => (
                        <tr key={diagnostic.id}>
                            <td>
                                <Link to={'/diagnostics/' + String(diagnostic.id)}>{convertToProperDate(diagnostic.dateAdded)} г.</Link>
                            </td>
                            {!props.patientId && 
                            <td>
                                {diagnostic.patient.name} {diagnostic.patient.family}, {diagnostic.patient.age} г.
                            </td>
                            }
                            <td className={classes.actions}>
                                {checkedRole !== userRoles.employee &&
                                <button className={classes.edit} onClick={() => {
                                    navigate('/diagnostics/' + String(diagnostic.id) + '/edit');
                                }}></button>
                                }
                                {checkedRole !== userRoles.employee && checkedRole !== userRoles.admin &&
                                <button className={classes.delete} onClick={() => {
                                    setModalIsShown(true);
                                    setElemKey(diagnostic.id);
                                }}></button>
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                : ''}
            </ListWrapper>
            {typeof diagnosticsData !== "undefined" ?
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={diagnosticsData.total}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
            : ''}
        </section>
        </>
    );
  }
  
  export default DiagnosticsWrapper;