import { useState } from "react";
import { Link, useRouteLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import Modal from "../../components/UI/Modal";
import UserCard from "../../components/UI/UserCard";
import { Api } from "../../utils/api";

const UserDetails = () => {
    const user = useRouteLoaderData('user-details');
    const checkedUser = useRouteLoaderData('users');

    const [modalIsShown, setModalIsShown] = useState(false);

    const navigate = useNavigate();

    const [serverErrors, setServerErrors] = useOutletContext(user.errors);

    const deleteUserHandler = (id) => {
        Api.deleteUser(id).then(async (result) => {
            if (result === true) {
                navigate('/users');
                setServerErrors([]);
            }
        },
        (errors) => {
            setServerErrors(errors.errors);
        });
    }

    return (
        <>
        {modalIsShown && 
        <Modal message={'Сигурни ли сте?'} onCancel={() => {setModalIsShown(false);}} onConfirm={() => {deleteUserHandler(user.id); setModalIsShown(false);}}></Modal>}
        <section className='lists'>
            <h1>{user.user.name} {user.user.family}<span className="action in-title">
                <button className="edit"
                    onClick={() => {
                            navigate('edit');
                        }}>
                </button>
                <button className="delete" 
                    onClick={() => {
                            setModalIsShown(true);
                        }}>
                </button>
            </span></h1>
            {/* <UserCard data={{name: user.user.name, family: user.user.family, role: user.user.role, email: user.user.email, dateAdded: '', lastModified: '', company: user.user.company.name.name}} role={checkedUser} /> */}
            <UserCard data={user.user} role={checkedUser} />
        </section>
        <div className="back">
            <Link to=".." relative="path">
                <button>← Потребители</button>
            </Link>
        </div>
        </>
    );
}

export default UserDetails;