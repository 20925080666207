import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import selectCustomStyles from '../../components/UI/SelectCustomStyles';
import ElasticityModel from '../../models/Elasticity';

// const muscleTendonOptions = [
//     { value: '1', label: ElasticityModel.getElasticityString('1') },
//     { value: '2', label: ElasticityModel.getElasticityString('2') },
//     { value: '3', label: ElasticityModel.getElasticityString('3') },
//     { value: '4', label: ElasticityModel.getElasticityString('4') },
//     { value: '5', label: ElasticityModel.getElasticityString('5') },
//     { value: '6', label: ElasticityModel.getElasticityString('6') },
//     { value: '7', label: ElasticityModel.getElasticityString('7') },
//     { value: '8', label: ElasticityModel.getElasticityString('8') },
//     { value: '9', label: ElasticityModel.getElasticityString('9') },
//   ];

const muscleTendonOptions = [
    { value: '1', label: 'в норма' },
    { value: '2', label: 'слаба мускулатура' },
  ];

const ElasticitySelect = ({control, name, label}) => {

    return (
        <div className='control-single-line'>
            <label>{label}</label>
            <div className='control-select'>
            <Controller
                control={control}
                render={({field:{onChange, value, name, ref}}) => (
                    <Select
                        inputRef={ref}
                        name={name}
                        options={muscleTendonOptions}
                        value={muscleTendonOptions.find(m => m.value === value)}
                        onChange={(selectedOption) => {
                            onChange(selectedOption.value);
                            }}
                        styles={selectCustomStyles}
                    />
                    )}
                name={name}
                // rules={{ required: true }}
            />
            </div>
        </div>
    );
};

export default ElasticitySelect;