import { Fragment } from "react";
import MainNavigation from "../components/Layout/MainNavigation";
import MessageCard from "../components/UI/MessageCard";

const NotFound = () => {
    return (
        <Fragment>
            <MainNavigation />
            <div className="lists">
                <MessageCard message="Страницата не съществува." />
            </div>
        </Fragment>
    )
};

export default NotFound;