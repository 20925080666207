import { useForm } from "react-hook-form";
import { Api } from '../../utils/api';

const ProfileForm = () => {

  const { register, getValues, formState: { errors }, handleSubmit } = useForm();

  const onSubmit = (newPassword) => {
    Api.setUsers(newPassword);
    //navigate('/profile');
  }

  return (
    <form className='form' onSubmit={handleSubmit(onSubmit)}>
      <p className='control'>
          <label>Нова парола:</label>
          <input {...register("password", { required: true })}
              className={errors.password ? "error-input" : ""}
              id="password"
              type="password"
          />
      </p>
      {errors.password && <p className='error-message'>Моля, въведете валидна парола.</p>}
      <p className='control'>
          <label>Потвърди нова парола:</label>
          <input {...register("confirm_password", { required: true, validate: (value) => {
                                                                      const { password } = getValues();
                                                                      return password === value || "Паролите не съвпадат";}}
                              )
              }
              className={errors.confirm_password ? "error-input" : ""}
              id="confirm_password"
              type="password"
          />
      </p>
      {errors.confirm_password?.type === 'required' && <p className='error-message'>Моля, въведете повторно парола.</p>}
      {errors.confirm_password && <p className='error-message'>{errors.confirm_password.message}</p>}
      <div className='action'>
        <button>Промени парола</button>
      </div>
    </form>
  );
}

export default ProfileForm;