import classes from './Modal.module.css';

const Backdrop = (props) => {
    return <div className={classes.backdrop} />
};

const ModalOverlay = (props) => {
    return (
        <div className={classes.modal}>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>
    );
};

const ModalColorpicker = (props) => {
    return (
        <>
            <Backdrop />
            <ModalOverlay>
                <h3>{props.message}</h3>
                <div style={{display:"flex", justifyContent:"center"}}>
                    <div className="left half">
                        <div>
                            <span style={{backgroundColor: "#f82626", width:"15px", height:"15px", display:"inline-block", marginRight:"1em", borderRadius:"3px"}}></span>
                            <span>- липсва чувствителност</span>
                        </div>
                        <div>
                            <span style={{backgroundColor: "#ffce08", width:"15px", height:"15px", display:"inline-block", marginRight:"1em", borderRadius:"3px"}}></span>
                            <span>- намалена чувствителност</span>
                        </div>
                        <div>
                            <span style={{backgroundColor: "#66c707", width:"15px", height:"15px", display:"inline-block", marginRight:"1em", borderRadius:"3px"}}></span>
                            <span>- нормална чувствителност</span>
                        </div>
                        <div>
                            <span style={{backgroundColor: "#b2b2b2", width:"15px", height:"15px", display:"inline-block", marginRight:"1em", borderRadius:"3px"}}></span>
                            <span>- липсва замерване</span>
                        </div>
                    </div>
                </div>
                <div className="action">
                    {["#f82626", "#ffce08", "#66c707", "#b2b2b2"].map((colorOption) => (
                        <button key={colorOption} onClick={() => props.onPick(colorOption)} style={{backgroundColor:colorOption, width:"30px", height:"50px"}}>
                            &nbsp;
                        </button>
                    ))}
                </div>
                <div className="action">
                    <button className={classes.cancel} onClick={props.onCancel}>{!props.cancelButton ? 'Отказ' : props.cancelButton}</button>
                </div>
            </ModalOverlay>
        </>
    );
};

export default ModalColorpicker;