import { convertToProperDate } from '../../utils/utils';
import classes from './PersonalCard.module.css';

const PersonalCard = (props) => {

    return (
        <>
        <div>{props.data.dateAdded && <span className={classes.dates}>Добавен на: {convertToProperDate(props.data.dateAdded)} г.</span>} {props.data.lastModified && <span className={classes.dates}>Последно обновен на: {convertToProperDate(props.data.lastModified)} г.</span>}
        </div>
        <div className={classes['card-wrapper']}>
            <div className={classes['data-piece']}>
                <span className={classes.label}>Име:</span>
                <span>{props.data.name} {props.data.family}</span>
            </div>
            <div className={classes['data-piece']}>
                <span className={classes.label}>Професия:</span>
                <span>{props.data.jobTitle}</span>
            </div>
            <div className={classes['data-piece']}>
                <span className={classes.label}>Възраст:</span>
                <span>{props.data.age} г.</span>
            </div>
            <div className={classes['data-piece']}>
                <span className={classes.label}>Пол:</span>
                <span>{props.data.sex}</span>
            </div>
        </div>
        </>
    );
};

export default PersonalCard;