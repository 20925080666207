 import { useLoaderData, useNavigate } from "react-router-dom";

import DiagnosticsWrapper from "../../components/UI/DiagnosticsWrapper";

const DiagnosticsSheetList = () => {
    const diagnosticsData= useLoaderData();
    const diagnostics = diagnosticsData.diagnostics;

    const navigate = useNavigate();

    return (
        <>
            <h1>Списък с диагностики</h1>
            <div className="action"><button onClick={() => {
                                    navigate('/diagnostics/new');
                                }}>Добави новa</button></div>
            <DiagnosticsWrapper data={diagnostics} patientId={null} errors={diagnosticsData.errors} />
        </>
    );
  }
  
  export default DiagnosticsSheetList;