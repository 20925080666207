
const FeetModel = {
    feet_norm: '1',
    feet_sup: '2',
    feet_pron: '3',
    feet_nlArches: '4',
    feet_hlArches: '5',
    feet_flArches: '6',
    feet_ntArches: '7',
    feet_ftArches: '8',
    feet_pressureMoreLeft: '9',
    feet_pressureMoreRight: '10',
    feet_flat: '11',
    feet_pflArches: '12',
    getFeetString: (feet) => {
        switch (feet) {
            case FeetModel.feet_norm: 
                return 'в норма';
            case FeetModel.feet_sup: 
                return 'супинация';
            case FeetModel.feet_pron: 
                return 'пронация';
            case FeetModel.feet_nlArches: 
                return 'надлъжен свод в норма';
            case FeetModel.feet_hlArches: 
                return 'висок надлъжен свод';
            case FeetModel.feet_flArches: 
                return 'паднал надлъжен свод';
            case FeetModel.feet_ntArches: 
                return 'напречен свод в норма';
            case FeetModel.feet_ftArches: 
                return 'паднал напречен свод';
            case FeetModel.feet_pressureMoreLeft: 
                return 'натоварване повече отляво';
            case FeetModel.feet_pressureMoreRight: 
                return 'натоварване повече отдясно';
            case FeetModel.feet_flat: 
                return 'дюстабан';
            case FeetModel.feet_pflArches: 
                return 'частично паднал надлъжен свод';
        }
    }
};

export default FeetModel;