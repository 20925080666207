import React from "react";
import thermographyDegrees from '../../pages/Diagnostics/Images/thermography_degrees.jpg';
const ThermographyCard = (props) => {

    return (
        <div className='multi-inp therm'>
            <div className="left-col">
                <h3>{props.title}</h3>
                {props.image && <img src={props.image} className='pic' alt="Термография" />}
                <p>{props.note}</p>
            </div>
            <div className="degrees">
                <div className="value">{props.degreesMax && <>{props.degreesMax}°</>}</div>
                <div style={{width: '40px', height: '264px'}}><img src={thermographyDegrees} style={{width: '21px', height: '264px'}} /></div>
                <div className="value">{props.degreesMin && <>{props.degreesMin}°</>}</div>
            </div>
        </div>
    );
};

export default ThermographyCard;