const CervicalModel = {
    cervical_norm: '1',
    cervical_possibleLord: '2',
    cervical_lord: '3',
    getCervicalString: (cervical) => {
        switch (cervical) {
            case CervicalModel.cervical_norm: 
                return 'в норма';
            case CervicalModel.cervical_possibleLord: 
                return 'съмнение за изгладена шийна лордоза, препоръки: рентгенова снимка';
            case CervicalModel.cervical_lord: 
                return 'изгладена шийна лордоза';
        }
    }
};

export default CervicalModel;