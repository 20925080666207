import { useState } from 'react';
import { Outlet} from 'react-router-dom';
import ErrorCard from '../components/UI/ErrorCard';

const ProtectedLayout = () => {

    const [serverErrors, setServerErrors] = useState([]);

    return (
        <>
            <div className='lists'>{serverErrors && serverErrors.length > 0 && <ErrorCard errors={serverErrors} />}</div>
            <Outlet context={[serverErrors, setServerErrors]} />
        </>
    );
};

export default ProtectedLayout;